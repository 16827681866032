import { render, staticRenderFns } from "./LegoCard.vue?vue&type=template&id=ce09593a&scoped=true&"
import script from "./LegoCard.vue?vue&type=script&lang=ts&"
export * from "./LegoCard.vue?vue&type=script&lang=ts&"
import style0 from "./LegoCard.vue?vue&type=style&index=0&id=ce09593a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce09593a",
  null
  
)

export default component.exports