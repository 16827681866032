// https://patorjk.com/software/taag/#p=display&f=Colossal&t=URL%20Params
import {
  EDeepKit2GET_LI_LII_LegoInput,
  ELegoGET_L_LegoInput,
  EKiLiPOSTReq_KiliInput,
  ELegoInstancePOSTReq_LegoInstanceInput,
  EDeepKit2GET_LI_LegoInstanceInput,
  EDeepKit2GET_LI_D_DCM_LegoInstanceInput,
  EDeepKit2GET_KI_KILI_KI_LegoInstanceInput,
  EDeepKit2GET_KI_KILI_LI_D_DCM_LegoInstanceInput,
  EDeepKit2GET_K_LI_D_DCM_LegoInstanceInput,
  EDeepKit2GET_K_LI_LegoInstanceInput,
  EDeepKit2GET_KI_KILI_KiliInput,
  EDeepKit2GET_K_KI_KILI_KiliInput,
  EDeepKit2GET_VariableLink,
  EDeepKit2GET_K_VariableLink,
  EDeepKit2GET_K_KI_KILI_KI_LegoInstanceInput,
} from "@/types/api-schema";
import {
  oflatten,
  lenumerate,
  lmap2o,
  reverpolate,
  oitems,
  strim,
  ovaluemap,
  lot2o,
  omerge,
  pyfalsey,
  falsey,
  odrop,
  okeys,
  oKeysToSnake,
  oKeysToCamel,
  oget,
  pathJoin,
  oset,
  lzipBy,
  pathFirst,
  ocopy,
  pathRest,
  lfindIndex,
  iterapply,
  isDate,
  okeep,
  isIndex,
  extractIndex,
  containsIndex,
  pathSplit,
  pathsJoin,
  range,
  lextend,
  sReplaceFromObj,
  isBoolean,
  isString,
  isSomethingNumeric,
  isNumber,
  lfirst,
  inEnum,
  enforceEnum,
  enforceEnumOrNull,
  isArray2,
  isBoolean2,
  lcontains,
  lnone,
  sToNumber,
} from "../brains/flang";
import {
  FlatSchemaConstraints,
  FlatSchemaElement,
  HowToHandleEmptyInput,
  InstanceSchemaElement,
  InstanceSchemaElementOnly,
  KitRunSchemaElement,
  KitRunSchemaElementOnly,
  LegoSchemaElement,
  ReferenceType,
  SchemaConstraints,
  SchemaElementValue,
  SingleSchemaElementValue,
  UIType,
} from "../types/general-flank-types";
import { v4 as uuidv4 } from "uuid";
import { zz } from "@/brains/malf";
const instanceSchemaElementDefaults: InstanceSchemaElementOnly = {
  defaultValue: null,
  defaultAmbiguousInput: null,
  useDefaultValue: false,
  paramSender: null,
  lockDefaultValue: false,
  hidden: false,
  note: "",
  tooltip: "",
  manuallyAdded: false,
  uuid: "",
  kiliInputId: null,
  legoInstanceInputId: null,
  variable: null,
  placeholder: null,
  dropdownId: null,
  dropdownOlId: null,
  dropdown: null,
};
const kitRunSchemaElementDefaults: KitRunSchemaElementOnly = {
  listElements: null,
  value: null,
  isValid: true,
};

export const spreadsheetFiller = {
  str: { jsonType: "string", component: "b-input", inputType: "text" },
  dict: { jsonType: "object", component: null, inputType: null },
  list: { jsonType: "array", component: null, inputType: null },
  int: {
    jsonType: "number",
    component: "b-input",
    inputType: "number",
    step: 1,
  },
  float: {
    jsonType: "number",
    component: "b-input",
    inputType: "number",
    step: "any",
  },
  "datetime.datetime": {
    jsonType: "string",
    component: "b-datetimepicker",
    inputType: null,
  },
  "datetime.date": {
    jsonType: "string",
    component: "b-datepicker",
    inputType: null,
  },
} as any;
/*

  .d88 8888888b.          88b.    .d8888b.                   d8b          888 d8b                   888    d8b                   
 d88P" 888  "Y88b         "Y88b  d88P  Y88b                  Y8P          888 Y8P                   888    Y8P                   
d88P   888    888           Y88b Y88b.                                    888                       888                          
888    888    888  .d88b.    888  "Y888b.    .d88b.  888d888 888  8888b.  888 888 88888888  8888b.  888888 888  .d88b.  88888b.  
888    888    888 d8P  Y8b   888     "Y88b. d8P  Y8b 888P"   888     "88b 888 888    d88P      "88b 888    888 d88""88b 888 "88b 
Y88b   888    888 88888888  d88P       "888 88888888 888     888 .d888888 888 888   d88P   .d888888 888    888 888  888 888  888 
 Y88b. 888  .d88P Y8b.    .d88P  Y88b  d88P Y8b.     888     888 888  888 888 888  d88P    888  888 Y88b.  888 Y88..88P 888  888 
  "Y88 8888888P"   "Y8888 88P"    "Y8888P"   "Y8888  888     888 "Y888888 888 888 88888888 "Y888888  "Y888 888  "Y88P"  888  888 
                                                                                                                                 
                                                                                                                                 
                                                                                                                                 

*/
export function strToRunrams(str: string | null): Record<string, any> {
  if (str === "" || str === null) {
    return {};
  }
  return JSON.parse(str);
}

// strToRunrams('{"@Name":"Mallory"}'); //=
// strToRunrams("") //=

export function runramsToStr(runrams: any): string {
  const respObj = JSON.stringify(runrams);
  return respObj;
}

// runramsToStr(null) //=
export function schemaToStr(
  schema: LegoSchemaElement[] | InstanceSchemaElement[]
): string {
  return JSON.stringify(
    schema
      //@ts-ignore
      .map((e: LegoSchemaElement | InstanceSchemaElement) =>
        _dropFromSchemaElement(e, ["uuid"])
      )
      .map((e: any) => oKeysToSnake(e))
  );
}
export function strToSchema(
  str: string | null
): LegoSchemaElement[] | InstanceSchemaElement[] {
  if (str === null || str === "") {
    return [];
  }

  return oKeysToCamel(JSON.parse(str));
}

export function interpolateRunrams(
  runrams: any,
  interpolations: Record<string, any> = {}
): any {
  // this line roughly accomplishes what getJSONValue does,
  // allowing things like dates to work
  const jsoned = JSON.parse(JSON.stringify(runrams));
  return ovaluemap(
    jsoned,
    (v) => {
      if (isString(v)) {
        return sReplaceFromObj(v as string, interpolations);
      } else {
        return v;
      }
    },
    { recurse: true }
  );
}

// interpolateRunrams(strToRunrams('{"@Name":"Mallory"}', { $$FLANK_USER_ID$$: "google|123" })); //=
// interpolateRunrams(
//   { a: { b: "$$FLANK_USER_ID$$" } },
//   { $$FLANK_USER_ID$$: "google|123" }
// ); //=
// interpolateRunrams(
//   {
//     hi: "$$FLANK_USER_ID$$",
//     there: "something",
//     nested: { a: "$$FLANK_USER_ID$$" },
//     array: ["$$FLANK_USER_ID$$", "something"],
//     num: 1,
//     nulll: null,
//     ds: new Date().toISOString(),
//     d: new Date(),
//   },
//   { $$FLANK_USER_ID$$: "google|123" }
// ); //=
// interpolateRunrams(
//   {
//     hi: "$$FLANK_USER_ID$$",
//   },
//   { $$FLANK_USER_ID$$: 3 }
// ); //=

export function interpolateValue(
  value: any,
  interpolations: Record<string, any> = {}
): any {
  if (isString(value)) {
    return sReplaceFromObj(value, interpolations);
  }
  return value;
}
// TODO: This return value doesn't seem to line up with the name of the function...
export function getValueStrRepr(
  e: KitRunSchemaElement
): string | number | boolean | Date | null {
  if (
    e.defaultAmbiguousInput === HowToHandleEmptyInput.explicitNull &&
    elementIsEmpty(e)
  ) {
    return ""; // different
  } else if (
    e.defaultAmbiguousInput === HowToHandleEmptyInput.emptyString &&
    elementIsEmpty(e)
  ) {
    return ""; // different
  } else if (e.jsonType === "string") {
    return JSON.parse(JSON.stringify(e.value));
  } else if (e.jsonType === "number") {
    if (e.value === "") {
      // this used to be NaN, but NaN gets converted to null when stringified
      return ""; // different
    }
    if (isNumber(e.value)) {
      // only test for actualy numbers, not numeric strings
      return JSON.stringify(e.value);
    }
    return JSON.parse(JSON.stringify(e.value));
  } else if (e.jsonType === "boolean") {
    if (isBoolean(e.value)) {
      return JSON.stringify(e.value);
    } else {
      return JSON.parse(JSON.stringify(e.value));
    }
  } else if (e.jsonType === "array" && isArray2(e.value)) {
    return e.value.join(",");
  }
  try {
    // This method doesn't return strings, but everything WILL be converted
    // to a JSON string, so we really we just want that stringified repr if all else fails
    return JSON.parse(JSON.stringify(e.value));
  } catch (error) {
    console.log("error parsing value in getJSON", error);
    if (!e.value) {
      return e.value;
    }
    return e.value?.toString();
  }
}
// getValueStrRepr({ value: new Date() }); //=
// getValueStrRepr({
//   defaultAmbiguousInput: HowToHandleEmptyInput.explicitNull,
//   value: "",
// }); //=
// getValueStrRepr({
//   jsonType: "array",
//   listElements: [{ jsonType: "string", value: "hi" }],
//   value: ["hi", "there"],
// }); //=
export function getJSONValue(
  e: KitRunSchemaElement
): string | number | boolean | Date | null {
  if (
    e.defaultAmbiguousInput === HowToHandleEmptyInput.explicitNull &&
    elementIsEmpty(e)
  ) {
    return null;
  } else if (
    e.defaultAmbiguousInput === HowToHandleEmptyInput.emptyString &&
    elementIsEmpty(e)
  ) {
    return "";
  } else if (e.jsonType === "string") {
    return JSON.parse(JSON.stringify(e.value));
  } else if (e.jsonType === "number") {
    if (e.value === "") {
      // this used to be NaN, but NaN gets converted to null when stringified
      return null;
    }
    if (isSomethingNumeric(e.value)) {
      return Number(e.value);
    }
    return JSON.parse(JSON.stringify(e.value));
  } else if (e.jsonType === "boolean") {
    if (isBoolean2(e.value)) {
      return e.value;
    } else {
      return JSON.parse(JSON.stringify(e.value));
    }
  }
  try {
    // This method doesn't return strings, but everything WILL be converted
    // to a JSON string, so we really we just want that stringified repr if all else fails
    return JSON.parse(JSON.stringify(e.value));
  } catch (error) {
    console.log("error parsing value in getJSON", error);
    if (!e.value) {
      return e.value;
    }
    return e.value?.toString();
  }
}

// getJSONValue({ jsonType: "string", value: new Date() }); //=
// getJSONValue({ jsonType: "string", value: "" }); //=
// getJSONValue({ jsonType: "string", value: null }); //=
// getJSONValue({ jsonType: "string", value: "hello" }); //=
// getJSONValue({ jsonType: "string", value: 1 }); //=
// getJSONValue({ jsonType: null, value: new Date() }); //=
// getJSONValue({ jsonType: "string", value: "$$FLANK_USER_ID$$" }); //=
// getJSONValue({ jsonType: "string", value: "$$FLANK_USER_ID$$" }); //=
// getJSONValue({ jsonType: "number", value: 1 }); //=
// getJSONValue({ jsonType: "number", value: 1.1 }); //=
// getJSONValue({ jsonType: "number", value: "1" }); //=
// getJSONValue({ jsonType: "number", value: "1.1" }); //=
// getJSONValue({ jsonType: "number", value: "1,001" }); //=
// getJSONValue({ jsonType: "number", value: "1,001.1" }); //=
// getJSONValue({ jsonType: "number", value: "" }); //=
// getJSONValue({ jsonType: "number", value: null }); //=
// getJSONValue({ jsonType: "number", value: "$$FLANK_USER_ID$$" }); //=
// getJSONValue({ jsonType: "number", value: "$$FLANK_USER_ID$$" }); //=
// getJSONValue({ jsonType: "boolean", value: false }); //=
// getJSONValue({ jsonType: "boolean", value: true }); //=
// getJSONValue({ jsonType: "boolean", value: "1" }); //=
// getJSONValue({ jsonType: "boolean", value: "T" }); //=
// getJSONValue({ jsonType: "boolean", value: "$$FLANK_USER_ID$$" }); //=
// getJSONValue({ jsonType: "boolean", value: "$$FLANK_USER_ID$$" }); //=
// getJSONValue({ jsonType: "array", value: "$$FLANK_USER_ID$$" }); //=
// getJSONValue({ jsonType: "array", value: [1, 2, 3] }); //=
// getJSONValue({ jsonType: "object", value: { a: 1 } }); //=
// getJSONValue({ jsonType: "object", value: "$$FLANK_USER_ID$$" }); //=

export function schemaElementToRunrams(
  e: KitRunSchemaElement,
  interpolations: Record<string, any> = {}
): Record<string, any> | any[] | null {
  if (e == null) {
    return null;
  } else if (pyfalsey(e)) {
    return {};
  } else if (e.jsonType === "object") {
    const props = ((e.objectPropertySchemas ?? []) as KitRunSchemaElement[])
      .filter(
        (p) =>
          !(
            p.defaultAmbiguousInput === HowToHandleEmptyInput.omit &&
            elementIsEmpty(e)
          )
      )
      .map((p: KitRunSchemaElement) =>
        schemaElementToRunrams(p, interpolations)
      );
    if (e.key) {
      //@ts-ignore
      return { [e.key]: omerge(...props) };
    } else {
      //@ts-ignore
      return omerge(...props);
    }
  } else if (e.jsonType === "array") {
    let listContents;
    if (
      e.listElementSchema?.jsonType === "object" ||
      e.listElementSchema?.jsonType === "array"
    ) {
      listContents =
        e.listElements?.map((le) =>
          schemaElementToRunrams(le, interpolations)
        ) ?? [];
    } else {
      listContents = e.listElements?.map((le) => getJSONValue(le)) ?? [];
    }
    if (e.key && e.key.length > 0) {
      return { [e.key]: listContents };
    } else {
      return listContents;
    }
  } else {
    return { [e.key]: getJSONValue(e) };
  }
}

// import pe from "./paramExamples";
// schemaElementToRunrams(pe.includeIndustries) //=
// schemaElementToRunrams({
//   jsonType: "array",
//   listElements: [{ jsonType: "string", value: "hi" }],
// }); //=
// schemaElementToRunrams({
//   jsonType: "object",
//   key: "hi",
//   objectPropertySchemas: [
//     {
//       key: "array",
//       jsonType: "array",
//       listElements: [{ jsonType: "string", value: "hi" }],
//     },
//   ],
// }); //=
// interpolateRunrams(schemaElementToRunrams({
//   jsonType: "object",
//   key: "hi",
//   objectPropertySchemas: [
//     {
//       key: "array",
//       jsonType: "array",
//       listElements: [{ jsonType: "string", value: "$$FLANK_USER_ID$$" }],
//     },
//     {
//       key: "two",
//       jsonType: "number",
//       value: "$$FLANK_USER_ID$$",
//     },
//     {
//       key: "dt",
//       jsonType: "string",
//       value: new Date(),
//     },
//     {
//       key: "string",
//       jsonType: "string",
//       value: "$$FLANK_USER_ID$$",
//     },
//   ],
// }), {$$FLANK_USER_ID$$: 2}; //=
// interpolateRunrams(schemaElementToRunrams({
//   jsonType: "number",
//   key: "num",
//   value: 3,
// }), {$$FLANK_USER_ID$$: 2}; //=

export function schemaToRunrams(
  schema: KitRunSchemaElement[],
  interpolations: Record<string, any> = {},
  { skipAllEmptyElements = false }: { skipAllEmptyElements?: boolean } = {}
): Record<any, unknown> {
  const objs = schema
    .filter(
      (e: KitRunSchemaElement) =>
        !(
          (e.defaultAmbiguousInput === HowToHandleEmptyInput.omit ||
            skipAllEmptyElements) &&
          elementIsEmpty(e)
        )
    )
    .map((e: KitRunSchemaElement) => schemaElementToRunrams(e, interpolations))
    .filter((e) => e !== null) as KitRunSchemaElement[];
  //@ts-ignore
  return omerge(...objs);
}

// import pe from "./paramExamples";
// schemaToRunrams(pe.declanExample); //=
// schemaElementToRunrams(null); //=
// schemaElementToRunrams({}); //=
// schemaElementToRunrams({ key: "hi", value: new Date() }); //=
// schemaElementToRunrams({ key: "hi", value: null }); //=
// schemaElementToRunrams(pe.pathBodyArrayObjs[0]); //=
// schemaElementToRunrams(pe.pathBodyArrayObjs[1]); //=
// schemaElementToRunrams(
//   { key: "hi", value: "$$FLANK_USER_ID$$", jsonType: "string" },
//   { $$FLANK_USER_ID$$: "google|1234" }
// ); //=
// schemaToRunrams(pe.pathBodyArrayObjs); //=
// schemaToRunrams(pe.stringArray); //=
// schemaToRunrams(pe.rootArray); //=
// schemaToRunrams(pe.stringArrayOfArrays); //=
// schemaToRunrams(pe.nan); //=
// schemaToRunrams(pe.explicitNull); //=
// schemaToRunrams(pe.omitted); //=

/*
8888888                            888          .d8888b.                                                       d8b                            
  888                              888         d88P  Y88b                                                      Y8P                            
  888                              888         888    888                                                                                     
  888   88888b.  88888b.  888  888 888888      888         .d88b.  88888b.  888  888  .d88b.  888d888 .d8888b  888  .d88b.  88888b.  .d8888b  
  888   888 "88b 888 "88b 888  888 888         888        d88""88b 888 "88b 888  888 d8P  Y8b 888P"   88K      888 d88""88b 888 "88b 88K      
  888   888  888 888  888 888  888 888         888    888 888  888 888  888 Y88  88P 88888888 888     "Y8888b. 888 888  888 888  888 "Y8888b. 
  888   888  888 888 d88P Y88b 888 Y88b.       Y88b  d88P Y88..88P 888  888  Y8bd8P  Y8b.     888          X88 888 Y88..88P 888  888      X88 
8888888 888  888 88888P"   "Y88888  "Y888       "Y8888P"   "Y88P"  888  888   Y88P    "Y8888  888      88888P' 888  "Y88P"  888  888  88888P' 
                 888                                                                                                                          
                 888                                                                                                                          
                 888                                                                                                                          
*/

export function constraintsToFlatConstraints(
  constraints: KitRunSchemaElement["constraints"]
): FlatSchemaConstraints {
  return {
    maximum: constraints.maximum ?? null,
    minimum: constraints.minimum ?? null,
    step: constraints.step ?? null,
    maxLength: constraints.maxLength ?? null,
    pattern: constraints.pattern ?? null,
    validationMessage: constraints.validationMessage ?? null,
    enum: constraints.enum ?? null,
  };
}
export function legoInputsToLegoSchema(
  inputs: ELegoGET_L_LegoInput[] | EDeepKit2GET_LI_LII_LegoInput[]
): LegoSchemaElement[] {
  return inputs.map(
    (i: ELegoGET_L_LegoInput | EDeepKit2GET_LI_LII_LegoInput) => {
      return {
        order: i.order,
        key: i.key ?? "",
        typeSystem: i.typeSystem ?? "",
        typeId: i.typeId ?? "",
        constraints: {
          enum: JSON.parse(i.cEnum ?? "[]") as string[],
          multipleOf: i.cMultipleOf,
          maximum: i.cMaximum,
          exclusiveMaximum: i.cExclusiveMaximum,
          minimum: i.cMinimum,
          exclusiveMinimum: i.cExclusiveMinimum,
          maxLength: i.cMaxLength,
          minLength: i.cMinLength,
          pattern: i.cPattern,
          maxItems: i.cMaxItems,
          minItems: i.cMinItems,
          uniqueItems: i.cUniqueItems,
          maxProperties: i.cMaxProperties,
          minProperties: i.cMinProperties,
          required: i.cRequired,
          readOnly: i.cReadOnly,
          writeOnly: i.cWriteOnly,
          nullable: i.cNullable,
          step: i.cStep,
          validationMessage: i.cValidationMessage,
        },
        jsonType: i.jsonType ?? "",
        component: i.component ?? "",
        inputType: i.inputType ?? "",
        objectPropertySchemas: i.objectPropertySchemas
          ? (strToSchema(i.objectPropertySchemas) as LegoSchemaElement[])
          : null,
        listElementSchema: i.listElementSchema
          ? (lfirst(
              strToSchema("[" + i.listElementSchema + "]")
            ) as LegoSchemaElement)
          : null,
        displayValue: null,
        groupHeader: null,
        componentWidth: null,
        legoInputId: i.legoInputId,
      };
    }
  );
}

export function instanceInputToInstanceSchemaElement(
  i:
    | EDeepKit2GET_KI_KILI_KI_LegoInstanceInput
    // | EDeepKit2GET_KI_KILI_LI_D_DCM_LegoInstanceInput
    | EDeepKit2GET_K_KI_KILI_KI_LegoInstanceInput
    // | EDeepKit2GET_K_KI_KILI_LI_D_DCM_LegoInstanceInput
    // | EDeepKit2GET_LI_D_DCM_LegoInstanceInpu // decoration key/val
    | EDeepKit2GET_K_LI_LegoInstanceInput
    // | EDeepKit2GET_K_LI_D_DCM_LegoInstanceInput // decoation key/val
    | EDeepKit2GET_LI_LegoInstanceInput
    | EDeepKit2GET_KI_KILI_KiliInput
    | EDeepKit2GET_K_KI_KILI_KiliInput
): InstanceSchemaElement {
  const el = {
    order: i.order,
    key: i.key ?? "",
    typeSystem: i.typeSystem ?? "",
    typeId: i.typeId ?? "",
    constraints: {
      enum:
        "legoInstanceInput" in i
          ? JSON.parse(
              i.dropdown?.enum ?? i.legoInstanceInput.dropdown?.enum ?? "[]"
            ) // if kili input
          : JSON.parse(i.dropdown?.enum ?? "[]"), // if lego instance input
      multipleOf: i.cMultipleOf,
      maximum: i.cMaximum,
      exclusiveMaximum: i.cExclusiveMaximum,
      minimum: i.cMinimum,
      exclusiveMinimum: i.cExclusiveMinimum,
      maxLength: i.cMaxLength,
      minLength: i.cMinLength,
      pattern: i.cPattern,
      maxItems: i.cMaxItems,
      minItems: i.cMinItems,
      uniqueItems: i.cUniqueItems,
      maxProperties: i.cMaxProperties,
      minProperties: i.cMinProperties,
      required: i.cRequired,
      readOnly: i.cReadOnly,
      writeOnly: i.cWriteOnly,
      nullable: i.cNullable,
      step: i.cStep,
      validationMessage: i.cValidationMessage,
    },
    jsonType: i.jsonType ?? "",
    component: i.component ?? "",
    inputType: i.inputType ?? "",
    objectPropertySchemas: i.objectPropertySchemas
      ? (strToSchema(i.objectPropertySchemas) as LegoSchemaElement[])
      : null,
    listElementSchema: i.listElementSchema
      ? (lfirst(
          strToSchema("[" + i.listElementSchema + "]")
        ) as LegoSchemaElement)
      : null,
    displayValue: i.displayValue,
    groupHeader: i.groupHeader,
    componentWidth: i.componentWidth,
    defaultValue: i.defaultValue as SchemaElementValue,
    defaultAmbiguousInput: enforceEnumOrNull(
      i.defaultAmbiguousInput ?? "",
      HowToHandleEmptyInput
    ),
    useDefaultValue: i.useDefaultValue ?? false,
    paramSender:
      i.paramSenderPath &&
      i.paramSenderUiType &&
      inEnum(i.paramSenderUiType, UIType) &&
      i.paramSenderReferenceType &&
      inEnum(i.paramSenderReferenceType, ReferenceType)
        ? {
            path: i.paramSenderPath,
            uiType: enforceEnum(i.paramSenderUiType, UIType),
            referenceType: enforceEnum(
              i.paramSenderReferenceType,
              ReferenceType
            ),
            olId: i.paramSenderOlId ?? "",
            references: {
              valueColField: i.paramSenderValueColField,
              displayColField: i.paramSenderDisplayColField,
              elementPath: i.paramSenderElementPath,
            },
          }
        : null,
    lockDefaultValue: i.lockDefaultValue ?? false,
    hidden: i.hidden ?? false,
    note: i.note ?? "",
    tooltip: i.tooltip ?? "",
    manuallyAdded: i.manuallyAdded ?? false,
    uuid: uuidv4(),
    legoInstanceInputId:
      "legoInstanceInputId" in i ? i.legoInstanceInputId : null,
    kiliInputId: "kiliInputId" in i ? i.kiliInputId : null,
    legoInputId:
      "legoInputId" in i
        ? (i as EDeepKit2GET_LI_LegoInstanceInput).legoInputId
        : "legoInstanceInput" in i
        ? (i as EDeepKit2GET_KI_KILI_KiliInput).legoInstanceInput.legoInputId
        : null,
    variable: i.variable,
    placeholder: i.placeholder,
    dropdownId: i.dropdownId,
    dropdownOlId: i.dropdown?.kit?.anchorOlId ?? null,
    dropdown: i.dropdown,
  };
  el.defaultValue = castStringValueToElementType(
    el.defaultValue as string | null,
    el
  );
  return el;
}

export function liOrKiliInputsToInstanceSchema(
  inputs:
    | EDeepKit2GET_KI_KILI_KI_LegoInstanceInput[]
    | EDeepKit2GET_K_KI_KILI_KI_LegoInstanceInput[]
    | EDeepKit2GET_LI_LegoInstanceInput[]
    | EDeepKit2GET_K_LI_LegoInstanceInput[]
    | EDeepKit2GET_KI_KILI_KiliInput[]
    | EDeepKit2GET_K_KI_KILI_KiliInput[]
): InstanceSchemaElement[] {
  //@ts-ignore
  return inputs.map(instanceInputToInstanceSchemaElement);
}

export function instanceElementToLegoInputRow(
  e: InstanceSchemaElement,
  legoId: number,
  index: number
): Omit<ELegoGET_L_LegoInput, "legoInputId"> {
  return {
    legoId: legoId,
    order: index,
    key: e.key,
    typeSystem: e.typeSystem,
    typeId: e.typeId,
    jsonType: e.jsonType,
    component: e.component,
    inputType: e.inputType,
    cMultipleOf: e.constraints.multipleOf ?? null,
    cMaximum: e.constraints.maximum ?? null,
    cExclusiveMaximum: e.constraints.exclusiveMaximum ?? null,
    cMinimum: e.constraints.minimum ?? null,
    cExclusiveMinimum: e.constraints.exclusiveMinimum ?? null,
    cMaxLength: e.constraints.maxLength ?? null,
    cMinLength: e.constraints.minLength ?? null,
    cPattern: e.constraints.pattern ?? null,
    cMaxItems: e.constraints.maxItems ?? null,
    cMinItems: e.constraints.minItems ?? null,
    cUniqueItems: e.constraints.uniqueItems ?? null,
    cMaxProperties: e.constraints.maxProperties ?? null,
    cMinProperties: e.constraints.minProperties ?? null,
    cRequired: e.constraints.required ?? null,
    cReadOnly: e.constraints.readOnly ?? null,
    cWriteOnly: e.constraints.writeOnly ?? null,
    cNullable: e.constraints.nullable ?? null,
    cEnum: e.constraints.enum ? JSON.stringify(e.constraints.enum) : null,
    cValidationMessage: e.constraints.validationMessage ?? null,
    cStep: e.constraints.step ?? null,
    objectPropertySchemas: e.objectPropertySchemas
      ? JSON.stringify(e.objectPropertySchemas)
      : null,
    listElementSchema: e.listElementSchema
      ? JSON.stringify(e.listElementSchema)
      : null,
  };
}
export function instanceElementToLegoInstanceInputRow(
  e: InstanceSchemaElement,
  index = -1
): Omit<ELegoInstancePOSTReq_LegoInstanceInput, "legoInputId"> {
  return {
    order: index > -1 ? index : e.order,
    key: e.key,
    typeSystem: e.typeSystem,
    typeId: e.typeId,
    jsonType: e.jsonType,
    component: e.component,
    inputType: e.inputType,
    cMultipleOf: e.constraints.multipleOf ?? null,
    cMaximum: e.constraints.maximum ?? null,
    cExclusiveMaximum: e.constraints.exclusiveMaximum ?? null,
    cMinimum: e.constraints.minimum ?? null,
    cExclusiveMinimum: e.constraints.exclusiveMinimum ?? null,
    cMaxLength: e.constraints.maxLength ?? null,
    cMinLength: e.constraints.minLength ?? null,
    cPattern: e.constraints.pattern ?? null,
    cMaxItems: e.constraints.maxItems ?? null,
    cMinItems: e.constraints.minItems ?? null,
    cUniqueItems: e.constraints.uniqueItems ?? null,
    cMaxProperties: e.constraints.maxProperties ?? null,
    cMinProperties: e.constraints.minProperties ?? null,
    cRequired: e.constraints.required ?? null,
    cReadOnly: e.constraints.readOnly ?? null,
    cWriteOnly: e.constraints.writeOnly ?? null,
    cNullable: e.constraints.nullable ?? null,
    cEnum: e.constraints.enum ? JSON.stringify(e.constraints.enum) : null,
    cValidationMessage: e.constraints.validationMessage ?? null,
    cStep: e.constraints.step ?? null,
    objectPropertySchemas: e.objectPropertySchemas
      ? JSON.stringify(e.objectPropertySchemas)
      : null,
    listElementSchema: e.listElementSchema
      ? JSON.stringify(e.listElementSchema)
      : null,
    displayValue: e.displayValue,
    groupHeader: e.groupHeader,
    componentWidth: e.componentWidth,
    defaultValue: !e.defaultValue
      ? null
      : JSON.stringify(e.defaultValue).replace(/^"|"$/g, ""),
    defaultAmbiguousInput: e.defaultAmbiguousInput,
    useDefaultValue: e.useDefaultValue,
    paramSenderPath: e.paramSender?.path ?? null,
    paramSenderUiType: e.paramSender?.uiType ?? null,
    paramSenderReferenceType: e.paramSender?.referenceType ?? null,
    paramSenderValueColField: e.paramSender?.references?.valueColField ?? null,
    paramSenderDisplayColField:
      e.paramSender?.references?.displayColField ?? null,
    paramSenderElementPath: e.paramSender?.references?.elementPath ?? null,
    lockDefaultValue: e.lockDefaultValue,
    hidden: e.hidden,
    note: e.note,
    tooltip: e.tooltip,
    manuallyAdded: e.manuallyAdded,
    variable: e.variable,
    placeholder: e.placeholder,
    dropdownId: e.dropdownId,
    paramSenderOlId: e.paramSender?.olId ?? null,
  };
}
export function instanceElementToKiliInputRow(
  e: InstanceSchemaElement,
  index = -1
): Omit<EKiLiPOSTReq_KiliInput, "legoInstanceInputId"> {
  return {
    order: index > -1 ? index : e.order,
    key: e.key,
    typeSystem: e.typeSystem,
    typeId: e.typeId,
    jsonType: e.jsonType,
    component: e.component,
    inputType: e.inputType,
    cMultipleOf: e.constraints.multipleOf ?? null,
    cMaximum: e.constraints.maximum ?? null,
    cExclusiveMaximum: e.constraints.exclusiveMaximum ?? null,
    cMinimum: e.constraints.minimum ?? null,
    cExclusiveMinimum: e.constraints.exclusiveMinimum ?? null,
    cMaxLength: e.constraints.maxLength ?? null,
    cMinLength: e.constraints.minLength ?? null,
    cPattern: e.constraints.pattern ?? null,
    cMaxItems: e.constraints.maxItems ?? null,
    cMinItems: e.constraints.minItems ?? null,
    cUniqueItems: e.constraints.uniqueItems ?? null,
    cMaxProperties: e.constraints.maxProperties ?? null,
    cMinProperties: e.constraints.minProperties ?? null,
    cRequired: e.constraints.required ?? null,
    cReadOnly: e.constraints.readOnly ?? null,
    cWriteOnly: e.constraints.writeOnly ?? null,
    cNullable: e.constraints.nullable ?? null,
    cEnum: e.constraints.enum ? JSON.stringify(e.constraints.enum) : null,
    cValidationMessage: e.constraints.validationMessage ?? null,
    cStep: e.constraints.step ?? null,
    objectPropertySchemas: e.objectPropertySchemas
      ? JSON.stringify(e.objectPropertySchemas)
      : null,
    listElementSchema: e.listElementSchema
      ? JSON.stringify(e.listElementSchema)
      : null,
    displayValue: e.displayValue,
    groupHeader: e.groupHeader,
    componentWidth: e.componentWidth,
    defaultValue: !e.defaultValue
      ? null
      : JSON.stringify(e.defaultValue).replace(/^"|"$/g, ""),
    defaultAmbiguousInput: e.defaultAmbiguousInput,
    useDefaultValue: e.useDefaultValue,
    paramSenderPath: e.paramSender?.path ?? null,
    paramSenderUiType: e.paramSender?.uiType ?? null,
    paramSenderReferenceType: e.paramSender?.referenceType ?? null,
    paramSenderValueColField: e.paramSender?.references?.valueColField ?? null,
    paramSenderDisplayColField:
      e.paramSender?.references?.displayColField ?? null,
    paramSenderElementPath: e.paramSender?.references?.elementPath ?? null,
    lockDefaultValue: e.lockDefaultValue,
    hidden: e.hidden,
    note: e.note,
    tooltip: e.tooltip,
    manuallyAdded: e.manuallyAdded,
    variable: e.variable,
    placeholder: e.placeholder,
    dropdownId: e.dropdownId,
    paramSenderOlId: e.paramSender?.olId ?? null,
  };
}
export function instanceSchemaToKiliPOSTKiliInputRows(
  schema: InstanceSchemaElement[]
): EKiLiPOSTReq_KiliInput[] {
  return schema.map((e: InstanceSchemaElement, index: number) => {
    return omerge(instanceElementToKiliInputRow(e, index), {
      legoInstanceInputId: e.legoInstanceInputId,
    });
  });
}
/*

888    d8P  d8b 888         8888888          d8b 888          .d8888b.           888     888               888          888            
888   d8P   Y8P 888           888            Y8P 888         d88P  "88b          888     888               888          888            
888  d8P        888           888                888         Y88b. d88P          888     888               888          888            
888d88K     888 888888        888   88888b.  888 888888       "Y8888P"           888     888 88888b.   .d88888  8888b.  888888 .d88b.  
8888888b    888 888           888   888 "88b 888 888         .d88P88K.d88P       888     888 888 "88b d88" 888     "88b 888   d8P  Y8b 
888  Y88b   888 888           888   888  888 888 888         888"  Y888P"        888     888 888  888 888  888 .d888888 888   88888888 
888   Y88b  888 Y88b.         888   888  888 888 Y88b.       Y88b .d8888b        Y88b. .d88P 888 d88P Y88b 888 888  888 Y88b. Y8b.     
888    Y88b 888  "Y888      8888888 888  888 888  "Y888       "Y8888P" Y88b       "Y88888P"  88888P"   "Y88888 "Y888888  "Y888 "Y8888  
                                                                                             888                                       
                                                                                             888                                       
                                                                                             888                                       
*/
export function initConstraints(
  partial: Partial<SchemaConstraints> = {}
): SchemaConstraints {
  return {
    multipleOf: partial.multipleOf ?? null,
    maximum: partial.maximum ?? null,
    exclusiveMaximum: partial.exclusiveMaximum ?? null,
    minimum: partial.minimum ?? null,
    exclusiveMinimum: partial.exclusiveMinimum ?? null,
    maxLength: partial.maxLength ?? null,
    minLength: partial.minLength ?? null,
    pattern: partial.pattern ?? null,
    maxItems: partial.maxItems ?? null,
    minItems: partial.minItems ?? null,
    uniqueItems: partial.uniqueItems ?? null,
    maxProperties: partial.maxProperties ?? null,
    minProperties: partial.minProperties ?? null,
    required: partial.required ?? null,
    readOnly: partial.readOnly ?? null,
    writeOnly: partial.writeOnly ?? null,
    nullable: partial.nullable ?? null,
    enum: partial.enum ?? null,
    validationMessage: partial.validationMessage ?? null,
    step: partial.step ?? null,
  };
}

// initConstraints({ nullable: true }); //=

export function initElement(
  inputElement: LegoSchemaElement | InstanceSchemaElement | null,
  { from, to }: { from: string | null; to: string },
  { manuallyAdded = false, key = "" } = {}
): InstanceSchemaElement | KitRunSchemaElement {
  const legoDefaults: LegoSchemaElement = {
    key: key,
    typeSystem: "python",
    typeId: "str",
    constraints: initConstraints(),
    jsonType: "string",
    component: "b-input",
    inputType: "text",
    objectPropertySchemas: null,
    listElementSchema: null,
    displayValue: null,
    groupHeader: null,
    componentWidth: null,
    order: 0,
    legoInputId: null,
  };
  let inputElement2 = inputElement ?? legoDefaults;
  if (inputElement && !("displayValue" in inputElement)) {
    // This is a migration
    inputElement2 = oset(inputElement, "displayValue", key);
  }
  if (inputElement && !("groupHeader" in inputElement)) {
    // This is a migration
    inputElement2 = oset(inputElement, "groupHeader", null);
  }
  if (inputElement && !("tooltip" in inputElement)) {
    // This is a migration
    inputElement2 = oset(inputElement, "tooltip", null);
  }
  if (inputElement && !("componentWidth" in inputElement)) {
    // This is a migration
    inputElement2 = oset(inputElement, "componentWidth", null);
  }
  const instanceDefaults: InstanceSchemaElementOnly = instanceSchemaElementDefaults;
  instanceDefaults.uuid = uuidv4();
  instanceDefaults.manuallyAdded = manuallyAdded;

  const kitRunDefaults: KitRunSchemaElementOnly = kitRunSchemaElementDefaults;
  kitRunDefaults.listElements =
    inputElement2.jsonType === "array" ? ([] as KitRunSchemaElement[]) : null;
  kitRunDefaults.value = elementEmptyValue(inputElement2);

  let defaults = {};
  const fields = okeys(omerge(legoDefaults, instanceDefaults, kitRunDefaults));
  const fromto = `${from ?? ""}->${to}`;
  if (fromto == "->kitrun") {
    defaults = omerge(legoDefaults, instanceDefaults, kitRunDefaults);
  } else if (fromto === "lego->kitrun") {
    defaults = omerge(instanceDefaults, kitRunDefaults);
  } else if (fromto === "lego->instance") {
    defaults = omerge(legoDefaults, instanceDefaults);
  } else if (fromto === "instance->kitrun") {
    defaults = omerge(instanceDefaults, kitRunDefaults);
  } else if (fromto === "instance->instance") {
    // for a potentially imcomplete instance...
    defaults = omerge(legoDefaults, instanceDefaults);
  }
  if (inputElement2.jsonType == "object") {
    const newLegoElement = oset(
      inputElement2,
      "objectPropertySchemas",
      //@ts-ignore
      inputElement2.objectPropertySchemas.map((e: any) =>
        initElement(e, { from, to })
      )
    );
    return okeep(omerge(defaults, newLegoElement), fields) as
      | KitRunSchemaElement
      | InstanceSchemaElement;
  } else if (inputElement2.jsonType == "array") {
    return okeep(omerge(defaults, inputElement2), fields) as
      | KitRunSchemaElement
      | InstanceSchemaElement;
  } else {
    return okeep(omerge(defaults, inputElement2), fields) as
      | KitRunSchemaElement
      | InstanceSchemaElement;
  }
}

export function trimDownSchema(
  schema: KitRunSchemaElement[],
  { from, to }: { from: string; to: string }
): InstanceSchemaElement[] {
  const fromto = `${from ?? ""}->${to}`;
  let fieldsToDrop = [] as string[];
  if (fromto == "kitrun->instance") {
    fieldsToDrop = okeys(kitRunSchemaElementDefaults);
  } else if (fromto == "kitrun->lego") {
    fieldsToDrop = okeys(kitRunSchemaElementDefaults).concat(
      okeys(instanceSchemaElementDefaults)
    );
  }
  return schema.map((e: any) => _dropFromSchemaElement(e, fieldsToDrop));
}

export function initSchema(
  inputSchema: LegoSchemaElement[] | InstanceSchemaElement[],
  { from, to }: { from: string; to: string }
): KitRunSchemaElement[] | InstanceSchemaElement[] {
  //@ts-ignore
  return inputSchema.map((e: any) => {
    return initElement(e, {
      from,
      to,
    });
  });
}

// import pe from "./paramExamples";
// initElement({} as LegoSchemaElement, { from: "instance", to: "kitrun" }); //=
// initElement(
//   {
//     key: "body",
//     jsonType: "object",
//     //@ts-ignore
//     objectPropertySchemas: [{ key: "a" }],
//     notafield: "don't keep this"
//   },
//   { from: "lego", to: "kitrun" }
// ); //=
// initSchema(strToSchema(pe.initializeExampleString), {
//   from: "lego",
//   to: "kitrun",
// })[0]; //=
// initSchema(strToSchema(pe.initializeExampleString), {
//   from: "lego",
//   to: "instance",
// })[0]; //=
export function injectLegoSchemaIntoKitRunSchema(
  kitRunSchema: KitRunSchemaElement[],
  legoSchema: LegoSchemaElement[]
): KitRunSchemaElement[] {
  return lzipBy(
    legoSchema,
    kitRunSchema,
    (le: any, kre: any) => kre.key === le.key,
    { keepLeft: true, keepRight: true }
  )
    .map(([le, kre]) => _injectLegoSchElemIntoKitRunSchElem(kre, le))
    .filter((e) => e !== null) as KitRunSchemaElement[];
}

// injectLegoSchemaIntoKitRunSchema(
//   [{ key: "a" }, { key: "b" }],
//   [{ key: "b" }, { key: "a" }]
// ); //=

export function _injectLegoSchElemIntoKitRunSchElem(
  kitRunElement: KitRunSchemaElement | null,
  legoElement: LegoSchemaElement | null
): KitRunSchemaElement | null {
  if (kitRunElement === null) {
    return initElement(legoElement, {
      from: "lego",
      to: "kitrun",
    }) as KitRunSchemaElement;
  } else if (legoElement === null) {
    if (kitRunElement.manuallyAdded) {
      return kitRunElement;
    } else {
      return null;
    }
  } else if (kitRunElement.manuallyAdded) {
    return kitRunElement;
  } else if (legoElement.jsonType == "object") {
    const merged = omerge(
      odrop(kitRunElement, ["objectPropertySchemas"]),
      odrop(legoElement, ["objectPropertySchemas"])
    ) as KitRunSchemaElement;
    return oset(
      merged,
      "objectPropertySchemas",
      lzipBy(
        legoElement.objectPropertySchemas ?? [],
        kitRunElement.objectPropertySchemas ?? [],
        (e1: any, e2: any) => e1.key === e2.key,
        { keepLeft: true, keepRight: true }
      ).map(([le, ie]) => _injectLegoSchElemIntoKitRunSchElem(ie, le))
    );
  } else if (legoElement.jsonType == "array") {
    const merged = omerge(kitRunElement, legoElement);
    const listElemSchema = legoElement.listElementSchema;
    if (listElemSchema) {
      return oset(
        merged,
        "listElements",
        kitRunElement.listElements?.map((ie) =>
          _injectLegoSchElemIntoKitRunSchElem(ie, listElemSchema)
        )
      );
    } else {
      return merged as KitRunSchemaElement;
    }
  } else {
    const merged = omerge(kitRunElement, legoElement, {
      value: kitRunElement.value,
    }) as KitRunSchemaElement;
    if (kitRunElement.typeId !== legoElement.typeId) {
      return oset(merged, "value", elementEmptyValue(merged));
    } else {
      return merged;
    }
  }
}

// const result = _injectLegoSchElemIntoKitRunSchElem(
//   {
//     jsonType: "array",
//     listElements: [
//       {
//         key: "",
//         typeId: "string",
//         value: "hi",
//         constraints: {},
//       } as KitRunSchemaElement,
//       {
//         key: "",
//         typeId: "string",
//         value: "there",
//         constraints: {},
//       } as KitRunSchemaElement,
//     ],
//   } as KitRunSchemaElement,
//   {
//     jsonType: "array",
//     constraints: {},
//     listElementSchema: {
//       key: "",
//       typeId: "number",
//       constraints: {},
//     } as LegoSchemaElement,
//   } as LegoSchemaElement
// ); //=
// result; //=
// const result2 = _injectLegoSchElemIntoKitRunSchElem(
//   {
//     jsonType: "object",
//     constraints: {},
//     objectPropertySchemas: [
//       {
//         key: "a",
//         typeId: "number",
//         value: "jim",
//         constraints: {},
//       } as KitRunSchemaElement,
//       {
//         key: "b",
//         typeId: "number",
//         value: "roger",
//         constraints: {},
//       } as KitRunSchemaElement,
//       {
//         key: "",
//         jsonType: "object",
//         constraints: {},
//         objectPropertySchemas: [
//           { key: "c", typeId: "number", value: "3" } as KitRunSchemaElement,
//         ],
//       },
//     ],
//   } as KitRunSchemaElement,
//   {
//     jsonType: "object",
//     constraints: {},
//     objectPropertySchemas: [
//       { key: "a", typeId: "string", constraints: {} } as LegoSchemaElement,
//       { key: "b", typeId: "string", constraints: {} } as LegoSchemaElement,
//       {
//         key: "",
//         constraints: {},
//         jsonType: "object",
//         objectPropertySchemas: [
//           { key: "c", typeId: "string", constraints: {} } as LegoSchemaElement,
//         ],
//       },
//       {
//         key: "newthing",
//         typeId: "string",
//         constraints: {},
//       } as LegoSchemaElement,
//     ],
//   } as LegoSchemaElement
// ); //=
// result2; //=
// lzipBy([1, 2, 3], [], (e1, e2) => e1 === e2, true); //=

export function unionSchema(
  leftSchema: InstanceSchemaElement[],
  rightSchema: InstanceSchemaElement[]
): InstanceSchemaElement[] {
  return lzipBy(leftSchema, rightSchema, (l: any, r: any) => l.key === r.key, {
    keepLeft: true,
    keepRight: true,
  }).map(([l, r]) => _unionElements(l, r));
}
export function _unionElements(
  left: InstanceSchemaElement,
  right: InstanceSchemaElement
): InstanceSchemaElement {
  // takes the right over the left
  // works recursively but doesn't do anything inside the element itself (i.e. on fields like value)
  if (right === null) {
    return left;
  } else if (left === null) {
    return right;
  } else if (right.jsonType !== left.jsonType) {
    return right;
  } else if (right.jsonType == "object") {
    return oset(
      right,
      "objectPropertySchemas",
      lzipBy(
        left.objectPropertySchemas ?? [],
        right.objectPropertySchemas ?? [],
        (e1: any, e2: any) => e1.key === e2.key,
        { keepLeft: true, keepRight: true }
      ).map(([l, r]) => _unionElements(l, r))
    );
  } else if (right.jsonType == "array") {
    return right;
  } else {
    return right;
  }
}

// _unionElements({ key: "l" }, null); //=
// _unionElements(null, { key: "r" }); //=
// _unionElements(
//   { key: "l", jsonType: "object" },
//   { key: "r", jsonType: "string" }
// ); //=
// _unionElements(
//   {
//     key: "same",
//     jsonType: "object",
//     objectPropertySchemas: [
//       { key: "l", manuallyAdded: true },
//       { key: "same2" },
//     ],
//   },
//   { key: "same", jsonType: "object", objectPropertySchemas: [{ key: "same2" }] }
// ); //=
// unionSchema([{key: "same"}, {key: "l", manuallyAdded: true}], [{key: "same"}]); //=

export function clearElementKitRunData(
  element: KitRunSchemaElement,
  condition = (e: KitRunSchemaElement) => true
): KitRunSchemaElement {
  let returnVal = setAllInElementSubtree(
    element,
    "value",
    (kre) => elementEmptyValue(kre),
    condition
  );
  returnVal = setAllInElementSubtree(
    returnVal,
    "isValid",
    (kre) => true,
    condition
  );
  returnVal = setAllInElementSubtree(
    returnVal,
    "listElements",
    (kre) => [],
    condition
  );
  return returnVal;
}

// clearElementKitRunData({
//   jsonType: "object",
//   objectPropertySchemas: [{ value: "hi" }],
// } as KitRunSchemaElement); //=

export function clearElementInSchema(
  schema: KitRunSchemaElement[],
  path: string
) {
  return schema.map((e) => {
    let returnVal = elementSet(e, path, (e) => elementEmptyValue(e), "value");
    returnVal = elementSet(returnVal, path, (e) => true, "isValid");
    returnVal = elementSet(returnVal, path, (e) => [], "listElements");
    return returnVal;
  });
}

// clearElementInSchema([{ key: "a", value: "hi" } as KitRunSchemaElement], "dne"); //=
// clearElementInSchema(
//   [
//     { key: "a", value: "hi" } as KitRunSchemaElement,
//     { key: "b", value: 13, jsonType: "number" } as KitRunSchemaElement,
//   ],
//   "b"
// ); //=

export function clearSchemaKitRunData(
  schema: KitRunSchemaElement[],
  clearHidden = true
): KitRunSchemaElement[] {
  return schema.map((e) =>
    clearElementKitRunData(
      e,
      clearHidden
        ? (el) => !el.lockDefaultValue
        : (el) => !el.lockDefaultValue && !el.hidden
    )
  );
}

// clearSchemaKitRunData(
//   [
//     { value: "hi", hidden: true },
//     { value: "hi", hidden: false },
//     { value: "hi", hidden: false, lockDefaultValue: true },
//   ],
//   false
// ); //=

export function setSchemaValuesToDefaults(schema: KitRunSchemaElement[]) {
  return schema.map((e) =>
    setAllInElementSubtree(
      e,
      "value",
      (el) => el.defaultValue,
      (el) => el.useDefaultValue
    )
  );
}

export function setSchemaValuesToVariables(
  schema: KitRunSchemaElement[],
  variables: { [key: string]: any }
) {
  return schema.map((e) => {
    let returnVal = e;
    for (const [k, v] of oitems(variables)) {
      returnVal = setAllInElementSubtree(
        returnVal,
        "value",
        (el) => v,
        (el) => el.variable === k
      );
    }
    return returnVal;
  });
}
export function bindRunramsToSchema(
  schema: KitRunSchemaElement[],
  runrams: any
): KitRunSchemaElement[] {
  return bindFlatParamsToSchema(schema, oflatten(runrams));
}

// bindRunramsToSchema(
//   [
//     { key: "a", value: "hi" },
//     { key: "b", value: "there" },
//   ],
//   { a: "new", b: "things" }
// ); //=
// oflatten({ a: [1, 2, 3] }); //=
// bindRunramsToSchema(
//   [
//     {
//       key: "a",
//       jsonType: "array",
//       listElementSchema: { key: "", jsonType: "number" },
//       listElements: [],
//     },
//   ],
//   { a: [1, 2, 3] }
// )[0].listElements; //=
/*

888    888 d8b      888      888                        888     888         888                            
888    888 Y8P      888      888                        888     888         888                            
888    888          888      888                        888     888         888                            
8888888888 888  .d88888  .d88888  .d88b.  88888b.       Y88b   d88P 8888b.  888 888  888  .d88b.  .d8888b  
888    888 888 d88" 888 d88" 888 d8P  Y8b 888 "88b       Y88b d88P     "88b 888 888  888 d8P  Y8b 88K      
888    888 888 888  888 888  888 88888888 888  888        Y88o88P  .d888888 888 888  888 88888888 "Y8888b. 
888    888 888 Y88b 888 Y88b 888 Y8b.     888  888         Y888P   888  888 888 Y88b 888 Y8b.          X88 
888    888 888  "Y88888  "Y88888  "Y8888  888  888          Y8P    "Y888888 888  "Y88888  "Y8888   88888P' 
                                                                                                           
                                                                                                           
                                                                                                           
*/
export function findHiddenUnlockedElements(
  schema: KitRunSchemaElement[]
): string[] {
  return schema
    .map((e) =>
      findAllKeysInElement(e, (el) => el.hidden && !el.lockDefaultValue)
    )
    .flat();
}

// findHiddenUnlockedElements([
//   { key: "a", hidden: true } as KitRunSchemaElement,
//   { key: "b" } as KitRunSchemaElement,
// ]); //=

export function findHiddenElements(schema: KitRunSchemaElement[]): string[] {
  return schema.map((e) => findAllKeysInElement(e, (el) => el.hidden)).flat();
}

/*

8888888888                                    888    
888                                           888    
888                                           888    
8888888    888  888 88888b.   .d88b.  888d888 888888 
888        `Y8bd8P' 888 "88b d88""88b 888P"   888    
888          X88K   888  888 888  888 888     888    
888        .d8""8b. 888 d88P Y88..88P 888     Y88b.  
8888888888 888  888 88888P"   "Y88P"  888      "Y888 
                    888                              
                    888                              
                    888                              
*/

const generateOptionsForEnums = (element: KitRunSchemaElement) => {
  const options = element.constraints["enum"] ?? [];
  let newSelectStr = `<select id="${element.key}" name="${element.key}"> `;
  options.map((opt: any) => {
    newSelectStr += ` <option value='${opt}'> ${opt} </option>`;
  });
  return newSelectStr + " </select>";
};
const hasOptionsConstraint = (element: KitRunSchemaElement) => {
  return okeys(oget(element, "constraints") ?? {}).includes("options");
};

// hasOptionsConstraint({}); //=
// hasOptionsConstraint({ constraints: {} }); //=
// hasOptionsConstraint({ constraints: { options: "something" } }); //=
// hasOptionsConstraint({
//   constraints: { some: "other", options: "something", stuff: "happening" },
// }); //=

export const convertParamSchemaToHTMLTemplate = (
  schema: KitRunSchemaElement[]
) => {
  //
  let newString = "";
  let newLabel;
  for (const topE of schema) {
    //for (let i = 0; i < 1; i++) {
    //
    newString += `<div style="margin: 12px 20px"> `;

    newLabel = `<label for="${topE.key}"> ${topE.key} </label>`;

    newString += newLabel;

    if (hasOptionsConstraint(topE)) {
      // if it has constraints
      console.log("we got an options guy");
      newString += generateOptionsForEnums(topE);
    } else if (topE.jsonType === "string") {
      newString += ` <input id="${topE.key}" name="${topE.key}"/> `;
    } else if (topE.jsonType === "boolean") {
      newString += ` <select id="${topE.key}" name="${topE.key}"> <option> true </option> <option> false </option></select> `;
    } else if (topE.jsonType === "integer") {
      newString += ` <input type="number" id="${topE.key}" name="${topE.key}"/> `;
    } else if (topE.jsonType === "number") {
      newString += ` <input type="number" id="${topE.key}" name="${topE.key}"/> `;
    }

    // console.log("thing ", `step ${level} `, topE);
    if (topE.objectPropertySchemas) {
      newString += convertParamSchemaToHTMLTemplate(
        topE.objectPropertySchemas as KitRunSchemaElement[]
      );
    }
    newString += ` </div>\n`;
  }
  // console.log("new STRING IS ", newString);
  return newString;
};

/*

888       888          d8b             888      888     888         888                            
888   o   888          Y8P             888      888     888         888                            
888  d8b  888                          888      888     888         888                            
888 d888b 888  .d88b.  888 888d888 .d88888      Y88b   d88P 8888b.  888 888  888  .d88b.  .d8888b  
888d88888b888 d8P  Y8b 888 888P"  d88" 888       Y88b d88P     "88b 888 888  888 d8P  Y8b 88K      
88888P Y88888 88888888 888 888    888  888        Y88o88P  .d888888 888 888  888 88888888 "Y8888b. 
8888P   Y8888 Y8b.     888 888    Y88b 888         Y888P   888  888 888 Y88b 888 Y8b.          X88 
888P     Y888  "Y8888  888 888     "Y88888          Y8P    "Y888888 888  "Y88888  "Y8888   88888P' 
                                                                                                   
                                                                                                   
                                                                                                   
*/

export function elementIsEmpty(element: KitRunSchemaElement): boolean {
  // don't treat empty checkboxes as empty
  if (element.component === "b-checkbox" && element.value === false) {
    return false;
  }
  if (element.jsonType === "array") {
    return (element.listElements ?? []).length === 0;
  }
  return element.value === elementEmptyValue(element);
}
export function elementIsRequired(element: KitRunSchemaElement): boolean {
  return element.defaultAmbiguousInput === null;
}
export function elementIsEmptyAndRequired(
  element: KitRunSchemaElement
): boolean {
  // don't treat empty checkboxes as ambiguous
  if (element.component === "b-checkbox" && element.value === false) {
    return false;
  }
  return (
    element.value === elementEmptyValue(element) &&
    element.defaultAmbiguousInput === null
  );
}

export function elementIsPopulatedOrOptional(element: KitRunSchemaElement) {
  return !elementIsEmptyAndRequired(element);
}
export function elementIsPopulated(element: KitRunSchemaElement): boolean {
  // don't treat empty checkboxes as empty
  if (element.component === "b-checkbox") {
    return true;
  }
  return element.value !== elementEmptyValue(element);
}

export function findInvalidElements(schema: KitRunSchemaElement[]): string[] {
  return schema
    .map((e) =>
      findAllKeysInElement(e, (e: KitRunSchemaElement) => !e.isValid, "")
    )
    .flat();
}

// const runrams = '{"job_title":"Data Engineer","locations":["Seattle"],"excluded_locations":[],"post_date":"Any time","work_type":[],"job_type":[],"keywords":[],"company_size":[],"investment_stage":[],"most_recent_investment_date":null,"include_industries":[],"exclude_industries":[],"exclude_staffing_agencies":false,"hiring_manager_job_title":["Chief Human Resources Officer","Head of Human Resources","Chief People Officer","Head of People","Chief Talent Officer","Head of Talent","Vice President Human Resources","VP HR","Director Human Resources","Business Partner Human Resources","Human Resources Manager","HR Manager","Senior Talent Acquisition Manager","Director Recruitment","Co-Founder","Founder","CEO","Managing Partner","President & CEO"],"exclude_hiring_manager_job_title":[],"pocs_per_company":1,"hierarchy_department":"hr","contact_method_preference":"email","use_flank_hm_identifier":false,"limit_contact_per_search":1,"user_id":"google-oauth2|104127113553280221549","user_name":"Mallory Price","subscription_id":null,"contact_blacklist_url":null,"continue_from_prev_run":false,"use_debounce":false,"OVERRIDE":"","flank_run_uuid":null,"return_at_step":"0","start_from_step":null,"start_from_source":null,"task_phase":"1"}';

// const valid = [{ key: "1", isValid: true }];
// findInvalidElements(valid as KitRunSchemaElement[]); //=

// const invalid = [{ key: "1", isValid: false }];
// findInvalidElements(invalid as KitRunSchemaElement[]); //=

// const invalidMultiple = [
//   { key: "1", isValid: false },
//   { key: "2", isValid: true },
//   { key: "3", isValid: false },
// ];
// findInvalidElements(invalidMultiple as KitRunSchemaElement[]); //=

// const invalidDeep = [
//   { key: "1", isValid: true },
//   { key: "2", isValid: true },
//   {
//     key: "3",
//     isValid: true,
//     jsonType: "object",
//     objectPropertySchemas: [
//       { key: "a", isValid: true },
//       { key: "b", isValid: false },
//     ],
//   },
//   {
//     key: "listy",
//     jsonType: "array",
//     isValid: true,
//     listElements: [{ key: "", isValid: false }],
//   },
// ];
// findInvalidElements(invalidDeep as KitRunSchemaElement[]); //=

export function getAllEmptyRequiredElements(
  schema: KitRunSchemaElement[]
): string[] {
  const emptyElements = schema
    .map((e) => findAllKeysInElement(e, elementIsEmptyAndRequired, ""))
    .flat();
  return emptyElements.map((e) => keyOrDisplay(schema, e));
}
export function getAllEmptyRequiredElementsWithVariable(
  schema: KitRunSchemaElement[]
): string[] {
  const emptyElements = schema
    .map((e) =>
      findAllKeysInElement(
        e,
        (e) => elementIsEmptyAndRequired(e) && e.variable !== null,
        ""
      )
    )
    .flat();
  return emptyElements.map((e) => keyOrDisplay(schema, e));
}
export function keyOrDisplay(
  schema: KitRunSchemaElement[],
  key: string
): string {
  const e = schema.find((e) => e.key === key);
  if (pyfalsey(e)) {
    return "";
  }
  // @ts-ignore
  if (!("displayValue" in e) || pyfalsey(e.displayValue)) {
    return key;
  } else {
    // @ts-ignore
    return e.displayValue;
  }
}
// import pe from "./paramExamples";
// getAllEmptyRequiredElements(pe.failingSchema[0] as KitRunSchemaElement); //=
// getAllEmptyRequiredElements(pe.failingSchema as KitRunSchemaElement[]); //=
// const e1 = {
//   key: "@bit",
//   typeSystem: "mssql",
//   typeId: "bit",
//   jsonType: "boolean",
//   component: "b-checkbox",
//   value: false,
//   defaultAmbiguousInput: null,
// };
// getAllEmptyRequiredElements([e1 as KitRunSchemaElement]); //=

/*

888     888 8888888b.  888           8888888b.                                                 
888     888 888   Y88b 888           888   Y88b                                                
888     888 888    888 888           888    888                                                
888     888 888   d88P 888           888   d88P 8888b.  888d888 8888b.  88888b.d88b.  .d8888b  
888     888 8888888P"  888           8888888P"     "88b 888P"      "88b 888 "888 "88b 88K      
888     888 888 T88b   888           888       .d888888 888    .d888888 888  888  888 "Y8888b. 
Y88b. .d88P 888  T88b  888           888       888  888 888    888  888 888  888  888      X88 
 "Y88888P"  888   T88b 88888888      888       "Y888888 888    "Y888888 888  888  888  88888P' 
                                                                                                                                                             
                                                                                               

*/
function _urlRegex(): RegExp {
  return /%22https?%3A(%2F){2}(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=,]*?)%22|%27https?%3A(%2F){2}(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=,]*?)%27/g;
}

function _quoteRegex(): RegExp {
  return /%3A%22.*?%22|%3A%27.*?%27/g;
}

function _placeholderReplace(
  str: string,
  type: string,
  prefix: string,
  regex: RegExp
) {
  const matches = str.match(regex);
  let replaced = str;
  const placeholders: Record<string, string> = {};
  for (const [i, r] of lenumerate(matches ?? []) as [number, string][]) {
    placeholders[`%%${type}PLACEHOLDER${i}%%`] = r.substring(prefix.length);
    replaced = replaced.replace(r, prefix + `%%${type}PLACEHOLDER${i}%%`);
  }
  return { replaced, placeholders };
}

// const path5 =
//   "/builder?legoId=193&params=url%3A%22https%3A%2F%2Fwww.linkedin.com%2Fsales%2Fsearch%2Fpeople%3FcompanySize%3DC&connectionOf=ACwAAAA5E68B15IreMM0m5pB8y4VqYDkkr0BDaU&doFetchHeroCard=false&logHistory=true&rsLogId=1077283946&searchSessionId=8Slme6ayRtyIzSZq%2F3p1tQ%3D%3D&titleExcluded=Chief%2520Technology%2520Officer%3A153,CTO&titleIncluded=Founder%3A35,Co-Founder%3A103&titleTimeScope=CURRENT&yearsOfExperience=3,4,5%22,url2:%22https%3A%2F%2Fwww.linkedin.com%2Fsales%2Fsearch%2Fpeople%3FcompanySize%3DC&connectionOf=ACwAAAA5E68B15IreMM0m5pB8y4VqYDkkr0BDaU&doFetchHeroCard=false&logHistory=true&rsLogId=1077283946&searchSessionId=8Slme6ayRtyIzSZq%2F3p1tQ%3D%3D&titleExcluded=Chief%2520Technology%2520Officer%3A153,CTO&titleIncluded=Founder%3A35,Co-Founder%3A103&titleTimeScope=CURRENT&yearsOfExperience=3,4,5%22";
// _placeholderReplace(path5, "URL", "", _urlRegex()); //=

function _parseKeyValues(pval: string) {
  const split = pval.split(",");
  const kvs = split
    .map((e) =>
      reverpolate(
        e,
        /{key}:::'{value}'/,
        /{key}:::"{value}"/,
        /{key}:::{value}/
      )
    )
    .filter((e) => "key" in e && "value" in e);
  const obj = lmap2o(
    kvs,
    (e) => e.key,
    (e) => e.value
  );

  return obj;
}

// _parseKeyValues("hi:there,this:that,number:5,bool:true"); //=

function _extractQueryStringItems(path: string): Record<string, string> {
  const qsMatch = path.match(/\?.*/g); //=
  if (qsMatch == null || qsMatch.length == 0) {
    return {};
  }

  return lot2o(Array.from(new URLSearchParams(qsMatch[0]).entries())) as Record<
    string,
    string
  >;
}

// const path6 =
//   "/builder?legoId=193&params=url%3A%22https%3A%2F%2Fwww.linkedin.com%2Fsales%2Fsearch%2Fpeople%3FcompanySize%3DC&connectionOf=ACwAAAA5E68B15IreMM0m5pB8y4VqYDkkr0BDaU&doFetchHeroCard=false&logHistory=true&rsLogId=1077283946&searchSessionId=8Slme6ayRtyIzSZq%2F3p1tQ%3D%3D&titleExcluded=Chief%2520Technology%2520Officer%3A153,CTO&titleIncluded=Founder%3A35,Co-Founder%3A103&titleTimeScope=CURRENT&yearsOfExperience=3,4,5%22,url2:%22https%3A%2F%2Fwww.linkedin.com%2Fsales%2Fsearch%2Fpeople%3FcompanySize%3DC&connectionOf=ACwAAAA5E68B15IreMM0m5pB8y4VqYDkkr0BDaU&doFetchHeroCard=false&logHistory=true&rsLogId=1077283946&searchSessionId=8Slme6ayRtyIzSZq%2F3p1tQ%3D%3D&titleExcluded=Chief%2520Technology%2520Officer%3A153,CTO&titleIncluded=Founder%3A35,Co-Founder%3A103&titleTimeScope=CURRENT&yearsOfExperience=3,4,5%22";
// _extractQueryStringItems(path6) //=

function _temporarilyReplaceUrlsAndQuotes(path: string) {
  const u = _placeholderReplace(path, "URL", "", _urlRegex());
  const q = _placeholderReplace(u.replaced, "QUOTE", "%3A", _quoteRegex());
  const placeholders = omerge(u.placeholders, q.placeholders);
  return { replaced: q.replaced, placeholders: placeholders };
}

// const path6 =
//   "/builder?legoId=193&params=url%3A%22https%3A%2F%2Fwww.linkedin.com%2Fsales%2Fsearch%2Fpeople%3FcompanySize%3DC&connectionOf=ACwAAAA5E68B15IreMM0m5pB8y4VqYDkkr0BDaU&doFetchHeroCard=false&logHistory=true&rsLogId=1077283946&searchSessionId=8Slme6ayRtyIzSZq%2F3p1tQ%3D%3D&titleExcluded=Chief%2520Technology%2520Officer%3A153,CTO&titleIncluded=Founder%3A35,Co-Founder%3A103&titleTimeScope=CURRENT&yearsOfExperience=3,4,5%22,url2:%22https%3A%2F%2Fwww.linkedin.com%2Fsales%2Fsearch%2Fpeople%3FcompanySize%3DC&connectionOf=ACwAAAA5E68B15IreMM0m5pB8y4VqYDkkr0BDaU&doFetchHeroCard=false&logHistory=true&rsLogId=1077283946&searchSessionId=8Slme6ayRtyIzSZq%2F3p1tQ%3D%3D&titleExcluded=Chief%2520Technology%2520Officer%3A153,CTO&titleIncluded=Founder%3A35,Co-Founder%3A103&titleTimeScope=CURRENT&yearsOfExperience=3,4,5%22";
// _temporarilyReplaceUrlsAndQuotes(path6); //=

function _subBackPlaceholderValues(
  params: Record<string, string>,
  placeholders: Record<string, string>
): Record<string, unknown> {
  const r = ovaluemap(params, (e: any) =>
    e in placeholders
      ? strim(decodeURIComponent(placeholders[e]), "'" + '"')
      : e
  );
  return r;
}

// _subBackPlaceholderValues(
//   { this: "%%PLACEHOLDER1%%" },
//   { "%%PLACEHOLDER1%%": "that" }
// ); //=

function parsePathAnchorTag(path: string): { path: string; anchor: string } {
  // regex that matches on the final anchor tag, up to the end of the string, and does not include an anchor tag or "%3A%3A%3A"
  const anchorRegex = /#(?!.*#)(?!.*%3A%3A%3A).*/;
  // if that exists, save it and trim it off the path
  const anchorMatch = path.match(anchorRegex);
  const anchor = anchorMatch ? anchorMatch[0] : "";
  const replaced = path.replace(anchorRegex, "");
  // return the anchor and the path
  return { path: replaced, anchor };
}

// parsePathAnchorTag(
//   "http://localhost:8080/flank-interns-1/builder?id=5171&runGroupId=1026&params=root.ki_523.li_4968.emotional%20words%3A%3A%3A%23d%23%23523_4968#d%23#523_4968"
// ); //=
// parsePathAnchorTag("/juju#1234"); //=
// parsePathAnchorTag("/juju?id%3A%3A%3Ahi#1234"); //=
// parsePathAnchorTag("/juju#12#34"); //=
// parsePathAnchorTag("/juju?id%3A%3A%3Ahi#and#1234"); //=
// parsePathAnchorTag("/juju#hi?id%3A%3A%3Ahi"); //=
// parsePathAnchorTag("/juju"); //=

export function pathToQueryParams(
  path: string,
  excludeMetaData = false
): Record<string, unknown> {
  // We need this because the built-in query string parser with
  // vue router does not parse some path strings (e.g. urls) correctly
  const { path: pathTrimmedNoAnchor, anchor } = parsePathAnchorTag(path);
  const {
    replaced: replacedNoAnchor,
    placeholders,
  } = _temporarilyReplaceUrlsAndQuotes(pathTrimmedNoAnchor);
  const qsObjNoAnchor = _extractQueryStringItems(replacedNoAnchor); //=
  if (falsey(qsObjNoAnchor.params)) {
    if (excludeMetaData) {
      return qsObjNoAnchor;
    } else {
      return oset(qsObjNoAnchor, "_anchor", anchor);
    }
  }
  const subbedBack = oset(
    qsObjNoAnchor,
    "params",
    _subBackPlaceholderValues(
      _parseKeyValues(qsObjNoAnchor.params as string) as Record<string, string>,
      placeholders as Record<string, string>
    )
  );
  if (excludeMetaData) {
    return subbedBack;
  } else {
    return oset(subbedBack, "_anchor", anchor);
  }
}

export function paramPartToQueryStringSection(
  paramPart: Record<string, string>
) {
  return oitems(paramPart)
    .map(([path, value]) => `${path}:::${value}`)
    .join(",");
}
export function queryParamsToQueryString(params: any): string {
  return oitems(params)
    .map(([k, v]) => {
      if (k == "params") {
        const paramString = paramPartToQueryStringSection(params.params);
        return `${k}=${paramString}`;
      } else {
        return `${k}=${v}`;
      }
    })
    .join("&");
}
// const p =
//   "/sandbox-212/builder?id=32435&groupRunId=711&params=root.ki_6426.li_32456.query%3A%3A%3A%22select%20%2a%20from%20users%20where%20phone_number%20is%20not%20null%22,root.ki_6425.li_32478.user_list%3A%3A%3A%22user_id,name,age,phone_number%0D%0A6,Bryce,21,%2B16109994523%0D%0A15,Mallory,23,%2B12534686926%0D%0A21,Angus,32,%2B18325097802%0D%0A%22,root.ki_6425.li_32478.message%3A%3A%3A%22You%20are%20on%20a%20HOT%20streak%21%22";
// const p =
//   "/sandbox-212/builder?id=32435&groupRunId=711&params=root.ki_6426.li_32456.query%3A%3A%3A%22select%20%2a%20from%20users%20where%20phone_number%20is%20not%20null%22&joinOrgToken=12345";
// const p =
//   "/flank-demos-1/builder?id=9352&runGroupId=2695&params=root.ki_281.li_9203.What%20is%20it%3F%3A%3A%3A%22panda%20in%20a%20ball%20pit%22,root.ki_283.li_9205.Emotions%3A%3A%3A%22negative%20mood,%20low%20energy%22,root.ki_285.li_9207.Size%20and%20structure%3A%3A%3A%22big%20and%20structured%22,root.ki_287.li_9209.Looks%20and%20vibes%3A%3A%3A%22gothic,%20fantasy%22,root.ki_289.li_9211.Camera%20proximity%3A%3A%3A%22long%20shot%22,root.ki_291.li_9213.Camera%20position%3A%3A%3A%22overhead%20view%22,root.ki_293.li_9215.Camera%20lens%3A%3A%3A%22wide%20angle%20lens,%2015mm%22,root.ki_295.li_9217.Outdoor%20lighting%3A%3A%3Aovercast,root.ki_297.li_9219.Indoor%20lighting%3A%3A%3A%22high-key%20lighting%22,root.ki_301.li_9223.Film%20style%3A%3A%3Apolaroid,root.ki_303.li_9225.Photo%20genre%3A%3A%3A%22candid%20street%20portrait%22,root.ki_280.li_9025.what_is_it%3A%3A%3A%22panda%20in%20a%20ball%20pit%22,root.ki_280.li_9025.emotion%3A%3A%3A%22negative%20mood,%20low%20energy%22,root.ki_280.li_9025.sizey_structurey_words%3A%3A%3A%22big%20and%20structured%22,root.ki_280.li_9025.looks_and_vibes%3A%3A%3A%22gothic,%20fantasy%22,root.ki_280.li_9025.camera_proximity%3A%3A%3A%22long%20shot%22,root.ki_280.li_9025.camera_position%3A%3A%3A%22overhead%20view%22,root.ki_280.li_9025.camera_lenses%3A%3A%3A%22wide%20angle%20lens,%2015mm%22,root.ki_280.li_9025.outdoor_lighting%3A%3A%3Aovercast,root.ki_280.li_9025.indoor_lighting%3A%3A%3A%22high-key%20lighting%22,root.ki_280.li_9025.film_styles%3A%3A%3Apolaroid,root.ki_280.li_9025.photo_genre%3A%3A%3A%22candid%20street%20portrait%22,root.ki_280.li_9025.portrait_photographer%3A%3A%3A,root.ki_280.li_9025.illustration_style%3A%3A%3A,root.ki_280.li_9025.art_history_style%3A%3A%3A,root.ki_280.li_9025.statue_style%3A%3A%3A,root.ki_280.li_9025.paper_and_textiles%3A%3A%3A,root.ki_280.li_9025.ceramics_and_glass%3A%3A%3A";
// pathToQueryParams("/orgs?params=k:::%22https%3A%2F%2Fwww.google.com%22"); //=
// pathToQueryParams(p); //=
// pathToQueryParams("/orgs", true); //=
// pathToQueryParams("/orgs?#hi", true); //=
// pathToQueryParams("/orgs?#hi"); //=
// pathToQueryParams("/store/builder?id=4979&goStraightToAuth0", true); //=
// queryParamsToQueryString(
//   odrop(pathToQueryParams(p), ["joinOrgToken"])
// ); //=

// const p1 =
//   "/flank-interns-1/builder?id=2994&runGroupId=1045&params=root.li_2976.%40userId%3A%3A%3A2";
// const p =
//   "/flank-interns-1/builder?id=2994&runGroupId=1045&params=%40userId%3A%3A%3A2";
// pathToQueryParams(p); //=
// const p =
//   "/sandbox-212/builder?id=32169&groupRunId=537&params=32302.path.AccountSid%3A%3A%3AAC013eb30ae5cafcbc676ff18a34690c48,32302.body.To%3A%3A%3A%2B18325097802,32302.body.StatusCallback%3A%3A%3A%25%25omit%25%25,32302.body.ApplicationSid%3A%3A%3A%25%25omit%25%25,32302.body.MaxPrice%3A%3A%3A%25%25omit%25%25,32302.body.ProvideFeedback%3A%3A%3Aomit,32302.body.Attempt%3A%3A%3A%25%25omit%25%25,32302.body.ValidityPeriod%3A%3A%3A%25%25omit%25%25,32302.body.ForceDelivery%3A%3A%3Aomit,32302.body.ContentRetention%3A%3A%3A%25%25omit%25%25,32302.body.AddressRetention%3A%3A%3A%25%25omit%25%25,32302.body.SmartEncoded%3A%3A%3A%25%25omit%25%25,32302.body.ShortenUrls%3A%3A%3A%25%25omit%25%25,32302.body.ScheduleType%3A%3A%3A%25%25omit%25%25,32302.body.SendAt%3A%3A%3A%25%25omit%25%25,32302.body.SendAsMms%3A%3A%3A%25%25omit%25%25,32302.body.ContentSid%3A%3A%3A%25%25omit%25%25,32302.body.ContentVariables%3A%3A%3A%25%25omit%25%25,32302.body.From%3A%3A%3A%2B17087872858,32302.body.MessagingServiceSid%3A%3A%3A%25%25omit%25%25,32302.body.Body%3A%3A%3AHello%21";
// const p =
//   "/sandbox-212/builder?id=32169&groupRunId=537&params=32302.body.ProvideFeedback%3A%3A%3A%25%25omit%25%25";
// const qp = pathToQueryParams(p).params; //=
// const p =
//   "/sandbox-119/builder?id=111&groupRunId=245&params=308.%40uniqueidentifer%3Ahello";
// const path =
//   "/builder?legoId=193&params=123%2Eurl%3A%22https%3A%2F%2Fwww.linkedin.com%2Fsales%2Fsearch%2Fpeople%3FcompanySize%3DC&connectionOf=ACwAAAA5E68B15IreMM0m5pB8y4VqYDkkr0BDaU&doFetchHeroCard=false&logHistory=true&rsLogId=1077283946&searchSessionId=8Slme6ayRtyIzSZq%2F3p1tQ%3D%3D&titleExcluded=Chief%2520Technology%2520Officer%3A153,CTO&titleIncluded=Founder%3A35,Co-Founder%3A103&titleTimeScope=CURRENT&yearsOfExperience=3,4,5%22,124%2Eurl2:%22https%3A%2F%2Fwww.linkedin.com%2Fsales%2Fsearch%2Fpeople%3FcompanySize%3DC&connectionOf=ACwAAAA5E68B15IreMM0m5pB8y4VqYDkkr0BDaU&doFetchHeroCard=false&logHistory=true&rsLogId=1077283946&searchSessionId=8Slme6ayRtyIzSZq%2F3p1tQ%3D%3D&titleExcluded=Chief%2520Technology%2520Officer%3A153,CTO&titleIncluded=Founder%3A35,Co-Founder%3A103&titleTimeScope=CURRENT&yearsOfExperience=3,4,5%22";
// const path2 =
//   "/builder?legoId=193&params=m1%3A%27hey,what%22sup%27,m2%3A%22https%3A%2F%2Fnotion.com/wea,dieadl%22";
// const path3 =
//   "/builder?legoId=193&params=m1%3A%27http://linkedin.com?p1=1%2C2&p2=with%20space%27";

// const { replaced, placeholders } = _temporarilyReplaceUrlsAndQuotes(p); //=
// replaced;
// placeholders;
// const qsObj = _extractQueryStringItems(replaced); //=
// const params = _parseKeyValues(qsObj.params); //=
// _subBackPlaceholderValues(
//   params as Record<string, string>,
//   placeholders as Record<string, string>
// ); //=

// pathToQueryParams(path); //=
// pathToQueryParams(path2).params; //=
// const routeQuery = pathToQueryParams(p); //=
// oget(routeQuery, "params"); //=

function _valueToUrlString(
  element: KitRunSchemaElement
): string | number | boolean | Date | null {
  if (
    ["b-datepicker", "b-datetimepicker", "b-timepicker"].includes(
      element.component
    ) &&
    isDate(element.value)
  ) {
    return (element.value as Date).toISOString();
  } else if (
    typeof element.value === "string" &&
    (element.value.includes(" ") ||
      element.value.includes(",") ||
      element.value.includes(":::"))
  ) {
    if (element.value.includes('"')) {
      return `'${element.value}'`;
    } else {
      return `"${element.value}"`;
    }
  } else if (isArray2(element.value)) {
    return JSON.stringify(element.value);
  }
  return element.value;
}

export function schemaToExcelPreppedString(
  legoPath: string,
  schema: KitRunSchemaElement[]
): string {
  return schemaToQueryString(legoPath, schema, {
    filter: (e) => true,
    valueTransformation: (e) =>
      elementIsEmpty(e) ? "%%%FLANKBLANK%%%" : _valueToUrlString(e),
  });
}
function _pathToKiId(path: string): number | null {
  const ids_split = pathSplit(path);
  if (ids_split.length < 3) return null;
  return sToNumber(ids_split[1].split("_")[1]);
}
export function schemaToQueryString(
  legoPath: string,
  schema: KitRunSchemaElement[],
  {
    filter = (el: KitRunSchemaElement) =>
      elementIsPopulated(el) && // MP: I think this is where we are having issues
      // no need to put a default value in URL because it gets fetched from DB anyway
      !(el.useDefaultValue && el.value === el.defaultValue),
    valueTransformation = (el: KitRunSchemaElement) => _valueToUrlString(el),
    variableLinks = [] as (
      | EDeepKit2GET_VariableLink
      | EDeepKit2GET_K_VariableLink
    )[],
  } = {}
): string {
  if (pyfalsey(schema)) {
    return "";
  }
  let filterPartial: (el: KitRunSchemaElement) => boolean;
  if (variableLinks.length === 0) {
    filterPartial = filter;
  } else {
    filterPartial = (el: KitRunSchemaElement) => {
      return (
        filter(el) &&
        !lnone(
          variableLinks,
          (vl: EDeepKit2GET_VariableLink | EDeepKit2GET_K_VariableLink) =>
            vl.receiverLegoInstanceInputId === el.legoInstanceInputId &&
            vl.receiverKiId === _pathToKiId(legoPath)
        )
      );
    };
  }
  return schema
    .map((e) =>
      findAllItemsInElement(e, filterPartial, "", valueTransformation)
    )
    .filter((el) => el.length !== 0)
    .flat()
    .map(([k, v]) => `${pathJoin(legoPath, k)}:::${v}`)
    .join(",");
}

// import emptyValSchema from "./paramExamples"; //=
//@ts-ignore
// schemaToQueryString("123", emptyValSchema.emptyValSchema); //=

// import pe from "./paramExamples";
// schemaToQueryString(123, pe.failingSchema, {
//   filter: (el) => elementIsPopulatedOrOptional(el),
//   valueTransformation: _valueToUrlString,
// }); //=
// schemaToQueryString(123, [pe.level3AmbigArray] as KitRunSchemaElement[], {
//   filter: (el) => elementIsPopulatedOrOptional(el),
//   valueTransformation: _valueToUrlString,
// }); //=

export function castStringValueToElementType(
  value: string | null,
  e: KitRunSchemaElement | LegoSchemaElement
): SchemaElementValue {
  if (value === null || value === "") {
    return elementEmptyValue(e);
  } else if (e.component === "b-checkbox") {
    return JSON.parse(value);
  } else if (
    ["b-datepicker", "b-datetimepicker", "b-timepicker"].includes(e.component)
  ) {
    const timezoneOffsetRegex = /([+-])(\d{2}):?(\d{2})$/;
    if (value.endsWith("Z") || value.match(timezoneOffsetRegex)) {
      return new Date(value);
    } else {
      return new Date(`${value}Z`);
    }
  } else if (e.jsonType === "array" && isArray2(value)) {
    if (isArray2(value)) {
      return value;
    } else if (isString(value)) {
      return strim(value as string, "[]").split(",");
    } else {
      return value;
    }
  } else {
    return value;
  }
}

function _fillSchemaArray(schema: KitRunSchemaElement[], path: string) {
  let sch = ocopy(schema);
  let remainingPathParts = pathSplit(path);
  while (remainingPathParts.some(isIndex)) {
    const idxOfIndex = lfindIndex(remainingPathParts, isIndex);
    const pathUpToIndex = pathsJoin(...remainingPathParts.slice(0, idxOfIndex));
    const valueOfIndex = extractIndex(remainingPathParts[idxOfIndex]);
    const listParent = elementGetInSchema(sch, pathUpToIndex);

    if (!listParent) {
      return sch;
    }
    if (valueOfIndex + 1 <= (listParent.listElements ?? []).length) {
      return sch;
    }
    // create list of length valueOfIndex
    const listElementSchema = listParent.listElementSchema;
    if (!listElementSchema) {
      return sch;
    }

    const newList = lextend(
      listParent.listElements ?? [],
      range((listParent.listElements ?? []).length, valueOfIndex + 1).map(
        (i) => {
          listElementSchema;
          return initElement(listElementSchema, {
            from: "lego",
            to: "kitrun",
          }) as KitRunSchemaElement;
        }
      ) as KitRunSchemaElement[]
    );
    sch = elementSetInSchema(
      sch,
      pathUpToIndex,
      (e) => newList,
      "listElements"
    );
    // set remainingPathParts to the remaiing part atter the index
    remainingPathParts = remainingPathParts.slice(idxOfIndex + 1);
  }
  return sch;
}

// const schemerz = _fillSchemaArray(
//   [
//     {
//       key: "hi",
//       jsonType: "array",
//       listElements: [],
//       constraints: {},
//       listElementSchema: {
//         key: "",
//         jsonType: "string",
//         value: "",
//         constraints: {},
//       },
//     },
//   ],
//   "hi[0]"
// );
// schemerz;
// _fillSchemaArray(schemerz, "hi[1]")[0].listElements; //=

export function bindFlatParamsToSchema(
  schema: KitRunSchemaElement[],
  flattenedParams: Record<string, string> | null,
  { skipLockedElements = true }: { skipLockedElements?: boolean } = {}
) {
  if (flattenedParams === null) {
    return schema;
  }
  return iterapply(
    schema,
    ...oitems(flattenedParams).map(([path, v]) => {
      return (sch: KitRunSchemaElement[]) => {
        // if path contains index n, create list elements up to n
        if (containsIndex(path)) {
          sch = _fillSchemaArray(sch, path);
        }
        if (
          (_getElementChainInSchema(sch, path) ?? []).some(
            (e) => e.lockDefaultValue
          ) &&
          skipLockedElements
        ) {
          // skip if param *or any of it's ancestors* are locked
          return sch;
        } else if (
          v === "%%omit%%" ||
          v === "%%emptyString%%" ||
          v === "%%explicitNull%%"
        ) {
          return sch;
        } else {
          return elementSetInSchema(sch, path, (e: KitRunSchemaElement) =>
            castStringValueToElementType(v as string, e)
          );
        }
      };
    })
  );
}
// bindFlatParamsToSchema(
//   [{ key: "hi", jsonType: "string", value: "", component: "b-datepicker" }],
//   {
//     hi: null,
//   }
// ); //=
// bindFlatParamsToSchema(
//   [{ key: "hi", jsonType: "array", value: null,listElementSchema: {key: '', jsonType: "string"}, listElements: [{component: "b-datepicker", value: null} }],
//   {
//     'hi[0]': "2023-01-01T00:00:00.000Z",
//   }
// )[0].listElements; //=
// bindFlatParamsToSchema(
//   [
//     {
//       key: "hi",
//       jsonType: "array",
//       listElements: [],
//       listElementSchema: {
//         constraints: {},
//         value: "",
//         jsonType: "string",
//         key: "there",
//       },
//       // listElements: [{ value: "" }],
//     },
//   ],
//   { "hi[0]": "1", "hi[1]": "2" }
// )[0].listElements; //=
// bindFlatParamsToSchema(
//   [
//     {
//       key: "hi",
//       jsonType: "object",
//       objectPropertySchemas: [{ key: "there", value: "" }],
//     },
//   ],
//   //{ "hi.there": 1 }
//   oflatten({ hi: { there: 1 } })
// )[0]; //=
// // skip if any parents are locked
// bindFlatParamsToSchema(
//   [
//     {
//       key: "hi",
//       jsonType: "object",
//       lockDefaultValue: true,
//       objectPropertySchemas: [{ key: "there", value: "" }],
//     },
//   ],
//   { "hi.there": 1 }
// )[0]; //=

export function bindFlatSchemaToSchema(
  schema: KitRunSchemaElement[],
  flatSchema: FlatSchemaElement[],
  { skipLockedElements = true }: { skipLockedElements?: boolean } = {}
): KitRunSchemaElement[] {
  const schemaCopy = ocopy(schema);
  const zippedList = lzipBy(flatSchema, schemaCopy, (f, e) => f.key === e.key);
  for (const [fl, el] of zippedList) {
    if (el.lockDefaultValue && skipLockedElements) {
      continue;
    }
    el.value = fl.value;
  }
  return schemaCopy;
}

/*

888     888 888    d8b 888          
888     888 888    Y8P 888          
888     888 888        888          
888     888 888888 888 888 .d8888b  
888     888 888    888 888 88K      
888     888 888    888 888 "Y8888b. 
Y88b. .d88P Y88b.  888 888      X88 
 "Y88888P"   "Y888 888 888  88888P' 
                                    
                                    
                                    
*/

function _elementGet(
  element: KitRunSchemaElement,
  path: string
): KitRunSchemaElement | null {
  if (
    path === element.key ||
    (isIndex(pathFirst(path)) &&
      pathRest(path).length === 0 &&
      element.key === "")
  ) {
    return element;
  } else if (
    element.jsonType === "object" &&
    (pathFirst(path) === element.key ||
      (isIndex(pathFirst(path)) && element.key === ""))
  ) {
    const childIdx = lfindIndex(
      element.objectPropertySchemas as any[],
      (e) => e.key === pathFirst(pathRest(path))
    );
    if (childIdx === -1) {
      return null;
    }
    return _elementGet(
      //@ts-ignore
      element.objectPropertySchemas[childIdx] as KitRunSchemaElement,
      pathRest(path)
    );
  } else if (
    element.jsonType === "array" &&
    (pathFirst(path) === element.key ||
      (isIndex(pathFirst(path)) && element.key === ""))
  ) {
    const childIdx = extractIndex(pathFirst(pathRest(path)));
    if (
      childIdx === -1 ||
      !("listElements" in element) ||
      childIdx >= (element.listElements ?? []).length
    ) {
      return null;
    }
    // if we're at the final part of the path, and it's something like "a[0]"
    if (pathRest(path) === pathFirst(pathRest(path))) {
      return (element.listElements ?? [])[childIdx];
    }

    return _elementGet(
      //@ts-ignore
      element.listElements[childIdx],
      pathRest(path)
    );
  }

  return null;
}

// _elementGet({ key: "a", value: 123 } as KitRunSchemaElement, "a"); //=
// _elementGet({ key: "a", value: 123 } as KitRunSchemaElement, "dne"); //=
// _elementGet(
//   {
//     key: "body",
//     value: null,
//     jsonType: "object",
//     objectPropertySchemas: [{ key: "b", value: 123 }],
//   } as KitRunSchemaElement,
//   "body.b"
// ); //=
// _elementGet(
//   {
//     key: "body",
//     value: null,
//     jsonType: "array",
//     listElements: [{ key: "b", value: 123 }],
//   } as KitRunSchemaElement,
//   "body[0]"
// ); //=
// _elementGet(
//   {
//     key: "body",
//     value: null,
//     jsonType: "array",
//     listElements: [
//       {
//         key: "",
//         jsonType: "object",
//         value: null,
//         objectPropertySchemas: [{ key: "c", value: 123 }],
//       },
//     ],
//   } as KitRunSchemaElement,
//   "body[0].c"
// ); //=
export function replaceElementInSchema(
  schema: KitRunSchemaElement[],
  path: string,
  replacement: KitRunSchemaElement
): KitRunSchemaElement[] {
  return schema.map((e) => replaceElement(e, path, replacement));
}
function replaceElement(
  element: KitRunSchemaElement,
  path: string,
  replacement: KitRunSchemaElement
): KitRunSchemaElement {
  return _elementApplyAt(element, path, (e) => replacement);
}
function _elementApplyAt(
  element: KitRunSchemaElement,
  path: string,
  f: (e: KitRunSchemaElement) => any
): KitRunSchemaElement {
  if (
    path === element.key ||
    (isIndex(pathFirst(path)) &&
      pathRest(path).length === 0 &&
      (element.key === "" ||
        element.key === undefined || // testing for element undefined helps with testing
        // this makes it so that you don't have to explicitly set key: "" in a dummy data structure
        (element.jsonType !== "object" && element.jsonType !== "array"))) // I think testing for jsonType is better than the key === "" check, but not sure if that would break old stuff...
  ) {
    return f(element);
  } else if (
    element.jsonType === "object" &&
    (pathFirst(path) === element.key ||
      (isIndex(pathFirst(path)) && element.key === ""))
  ) {
    const childIdx = lfindIndex(
      element.objectPropertySchemas as any[],
      (e) => e.key === pathFirst(pathRest(path))
    );
    if (childIdx === -1) {
      return element;
    }
    const copy = ocopy(element);
    //@ts-ignore
    copy.objectPropertySchemas[childIdx] = _elementApplyAt(
      //@ts-ignore
      copy.objectPropertySchemas[childIdx] as KitRunSchemaElement,
      pathRest(path),
      f
    );
    return copy;
  } else if (
    element.jsonType === "array" &&
    (pathFirst(path) === element.key ||
      (isIndex(pathFirst(path)) && element.key === ""))
  ) {
    const childIdx = extractIndex(pathFirst(pathRest(path)));
    if (
      childIdx === -1 ||
      !("listElements" in element) ||
      childIdx >= (element.listElements ?? []).length
    ) {
      return element;
    }
    const copy = ocopy(element);
    //@ts-ignore
    copy.listElements[childIdx] = _elementApplyAt(
      //@ts-ignore
      copy.listElements[childIdx],
      pathRest(path),
      f
    );
    return copy;
  }

  return element;
}

export function elementSet(
  element: KitRunSchemaElement,
  path: string,
  valueFunc: (e: KitRunSchemaElement) => any,
  attribute = "value"
): KitRunSchemaElement {
  return _elementApplyAt(element, path, (e) =>
    oset(e, attribute, valueFunc(e))
  );
}

// elementSet({ key: "a", value: "" } as KitRunSchemaElement, "a", "weeee"); //=
// elementSet(
//   {
//     key: "a",
//     jsonType: "object",
//     value: null,
//     objectPropertySchemas: [{ key: "b", value: "" }],
//   } as KitRunSchemaElement,
//   "a.b",
//   "weeee"
// ); //=
// elementSet(
//   {
//     key: "a",
//     jsonType: "array",
//     value: null,
//     listElements: [{ key: "", value: "" }],
//   } as KitRunSchemaElement,
//   "a[0]",
//   "weeee"
// ); //=
// elementSet(
//   {
//     key: "a",
//     jsonType: "array",
//     value: null,
//     listElements: [
//       {
//         key: "",
//         jsonType: "object",
//         value: null,
//         objectPropertySchemas: [{ key: "c", value: "" }],
//       } as KitRunSchemaElement,
//     ],
//   } as KitRunSchemaElement,
//   "a[0].c",
//   "weeee"
// ).listElements; //=
// elementSet(
//   {
//     key: "body",
//     value: null,
//     jsonType: "array",
//     listElements: [
//       {
//         key: "",
//         jsonType: "object",
//         value: null,
//         objectPropertySchemas: [{ key: "c", value: 123 }],
//       },
//     ],
//   } as KitRunSchemaElement,
//   "body[0].c",
//   (e) => "weee"
// ).listElements[0].objectPropertySchemas; //=

export function elementSetInSchema(
  schema: KitRunSchemaElement[],
  path: string,
  valueFunc: (e: KitRunSchemaElement) => any,
  attribute = "value"
) {
  return schema.map((e) => elementSet(e, path, valueFunc, attribute));
}

// elementSetInSchema([{ key: "a", value: "hi" } as KitRunSchemaElement], "dne", "whao"); //=
// elementSetInSchema(schemerz, "hi[0]", (e) => "1")[0].listElements; //=

export function elementGetInSchema(
  schema: KitRunSchemaElement[],
  path: string
) {
  for (const e of schema) {
    const result = _elementGet(e, path);
    if (result !== null) {
      return result;
    }
  }
  return null;
}

function _getElementChainInSchema(
  schema: KitRunSchemaElement[],
  path: string
): KitRunSchemaElement[] | null {
  const pathParts = pathSplit(path);
  const result = [];
  for (let i = 0; i < pathParts.length; i++) {
    const pathUpToIndex = pathsJoin(...pathParts.slice(0, i + 1));
    const element = elementGetInSchema(schema, pathUpToIndex);
    if (element === null) {
      return null;
    }
    result.push(element);
  }
  return result;
}

// _getElementChainInSchema([{ key: "a", value: "hi" }], "a"); //=
// _getElementChainInSchema(
//   [
//     {
//       key: "a",
//       value: "hi",
//       jsonType: "object",
//       objectPropertySchemas: [{ key: "b" }],
//     },
//   ],
//   "a.b"
// ); //=
// _getElementChainInSchema(
//   [
//     {
//       key: "a",
//       value: "hi",
//       jsonType: "array",
//       listElements: [{ key: "b" }],
//     },
//   ],
//   "a[0]"
// ); //=
// _getElementChainInSchema(
//   [
//     {
//       key: "a",
//       value: "hi",
//       jsonType: "object",
//       objectPropertySchemas: [{ key: "b" }],
//     },
//   ],
//   "a.z"
// ); //=

function _findAllInElement(
  element: KitRunSchemaElement,
  condition: (e: KitRunSchemaElement) => boolean,
  pathToHere = "",
  returnType = "keys" as "keys" | "values" | "items",
  valueTransformation: (
    el: KitRunSchemaElement
  ) =>
    | KitRunSchemaElement
    | string
    | number
    | boolean
    | Date
    | null
    | (string | number | boolean | Date | null)[] = (el: KitRunSchemaElement) =>
    el.value,
  { flattenListElements = true }: { flattenListElements?: boolean } = {}
): (
  | string // keys
  | [
      string,
      (
        | string
        | number
        | boolean
        | Date
        | null
        | (string | number | boolean | Date | null)[]
        | KitRunSchemaElement
      )
    ] // items
  | (
      | string
      | number
      | boolean
      | Date
      | null
      | (string | number | boolean | Date | null)[]
      | KitRunSchemaElement
    ) // values
)[] {
  if (element.jsonType === "object") {
    return (element.objectPropertySchemas ?? [])
      .map((child) =>
        _findAllInElement(
          child as KitRunSchemaElement,
          condition,
          pathJoin(pathToHere, element.key),
          returnType,
          valueTransformation,
          { flattenListElements }
        )
      )
      .flat();
  } else if (element.jsonType === "array") {
    if (flattenListElements) {
      return (element.listElements ?? [])
        .map((child, index) =>
          _findAllInElement(
            child,
            condition,
            pathJoin(pathToHere, `${element.key}[${index}]`),
            returnType,
            valueTransformation,
            { flattenListElements }
          )
        )
        .flat();
    } else {
      if (condition(element)) {
        if (returnType === "keys") {
          return [pathJoin(pathToHere, element.key)];
        } else {
          const singleValue = (element.listElements ?? []).map(
            (child) => child.value as SingleSchemaElementValue
          );
          element.value = singleValue;
          if (returnType === "values") {
            return [valueTransformation(element)];
          } else if (returnType === "items") {
            return [
              [pathJoin(pathToHere, element.key), valueTransformation(element)],
            ];
          }
        }
      }
    }
  } else {
    if (condition(element)) {
      if (returnType == "keys") {
        return [pathJoin(pathToHere, element.key)];
      } else if (returnType == "items") {
        return [
          [pathJoin(pathToHere, element.key), valueTransformation(element)],
        ];
      } else if (returnType == "values") {
        return [valueTransformation(element)];
      }
    }
  }
  return [];
}

// _findAllInElement(
//   {
//     key: "root",
//     jsonType: "array",
//     listElements: [
//       { key: "", jsonType: "string", value: "hi" },
//       { key: "", jsonType: "string", value: "there" },
//     ],
//   },
//   (e) => true,
//   "",
//   "items",
//   (e) => e.value
//   { flattenListElements: false }
// ); //=
// _findAllInElement(
//   {
//     key: "root",
//     jsonType: "object",
//     objectPropertySchemas: [
//       {
//         key: "arr",
//         jsonType: "array",
//         listElements: [
//           { key: "", jsonType: "string", value: "hi" },
//           { key: "", jsonType: "string", value: "there" },
//         ],
//       },
//     ],
//     value: null,
//   },
//   (e) => true,
//   "",
//   "keys",
//   (e) => e.value,
//   { flattenListElements: true }
// ); //=

export function findAllKeysInElement(
  element: KitRunSchemaElement,
  condition: (e: KitRunSchemaElement) => boolean,
  pathToHere = ""
): string[] {
  return _findAllInElement(element, condition, pathToHere, "keys") as string[];
}

// import pe from "./paramExamples";
// findAllKeysInElement({} as KitRunSchemaElement, elementIsEmptyAndRequired); //=
// findAllKeysInElement(
//   {
//     key: "hi",
//     value: "",
//     defaultAmbiguousInput: null,
//   } as KitRunSchemaElement,
//   elementIsEmptyAndRequired
// ); //=
// findAllKeysInElement(
//   {
//     key: "hi",
//     value: "",
//     defaultAmbiguousInput: "omit",
//   } as KitRunSchemaElement,
//   elementIsEmptyAndRequired
// ); //=
// findAllKeysInElement(pe.level2Ambig as KitRunSchemaElement, elementIsEmptyAndRequired); //=
// findAllKeysInElement(pe.level3Ambig as KitRunSchemaElement, elementIsEmptyAndRequired); //=
// findAllKeysInElement(pe.level3AmbigArray as KitRunSchemaElement, elementIsEmptyAndRequired); //=

export function findAllItemsInElement(
  element: KitRunSchemaElement,
  condition: (e: KitRunSchemaElement) => boolean,
  pathToHere = "",
  valueTransformation: (
    el: KitRunSchemaElement
  ) =>
    | KitRunSchemaElement
    | string
    | number
    | boolean
    | Date
    | null
    | (string | number | boolean | Date | null)[] = (el: KitRunSchemaElement) =>
    el,
  { flattenListElements = true }: { flattenListElements?: boolean } = {}
): [string, string | number | boolean | Date | null | KitRunSchemaElement][] {
  // where "item" means [path, value] (and value can be transformed)
  return _findAllInElement(
    element,
    condition,
    pathToHere,
    "items",
    valueTransformation,
    { flattenListElements }
  ) as [string, string | number | boolean | Date | null][];
}

// import pe from "./paramExamples";
// findAllItemsInElement(
//   pe.level3AmbigArray as KitRunSchemaElement,
//   (e) => elementIsEmptyAndRequired(e)
// ); //=
// findAllItemsInElement(
//   {key: "heezy", jsonType:"object", objectPropertySchemas: [{ key: "whoop", paramSender: { key: "whoop" }] },
// (e) => e.paramSender !== null
// ); //=
// findAllItemsInElement(
//   {key: "heezy", jsonType:"number", value:3 },
// (e) => true
// ); //=

export function findAllItemsInSchema(
  schema: KitRunSchemaElement[],
  condition = (e: KitRunSchemaElement) => true,
  valueTransformation = (el: KitRunSchemaElement) => el,
  { flattenListElements = true }: { flattenListElements?: boolean } = {}
): [string, string | number | boolean | Date | null | KitRunSchemaElement][] {
  return schema
    .map((e) =>
      findAllItemsInElement(e, condition, "", valueTransformation, {
        flattenListElements,
      })
    )
    .flat();
}

export function elementEmptyValue(
  e: KitRunSchemaElement | LegoSchemaElement
): string | number | boolean | null {
  if (e.jsonType === "array" || e.jsonType == "object") {
    return null;
  } else if ("paramSender" in e && e.paramSender !== null) {
    // for b-select, the "empty" value is null
    return null;
  } else if (e.component === "b-checkbox") {
    if (!("defaultAmbiguousInput" in e) || e.defaultAmbiguousInput === null) {
      return false;
    } else {
      return null;
    }
  } else if (
    ["b-datepicker", "b-datetimepicker", "b-timepicker"].includes(e.component)
  ) {
    return null;
  } else if (e.constraints?.enum && e.constraints.enum.length > 0) {
    return null;
  } else {
    return "";
  }
}

export function setAllInElementSubtree(
  element: KitRunSchemaElement,
  fieldName: string,
  valueCallback: (e: KitRunSchemaElement) => any,
  condition = (e: KitRunSchemaElement) => true
): KitRunSchemaElement {
  if (element.jsonType == "object") {
    let returnVal = oset(
      element,
      "objectPropertySchemas",
      (element.objectPropertySchemas as KitRunSchemaElement[]).map((e) =>
        setAllInElementSubtree(e, fieldName, valueCallback, condition)
      )
    );
    returnVal = oset(returnVal, fieldName, valueCallback(element));
    return returnVal;
  } else if (element.jsonType == "array") {
    let returnVal = oset(
      element,
      "listElements",
      element.listElements?.map((e) =>
        setAllInElementSubtree(e, fieldName, valueCallback, condition)
      )
    );
    returnVal = oset(returnVal, fieldName, valueCallback(element));
    return returnVal;
  } else {
    if (condition(element)) {
      return oset(element, fieldName, valueCallback(element));
    } else {
      return element;
    }
  }
}

export function setAllInSchemaSubtree(
  schema: KitRunSchemaElement[],
  fieldName: string,
  valueCallback: (e: KitRunSchemaElement) => any,
  condition = (e: KitRunSchemaElement) => true
): KitRunSchemaElement[] {
  return schema.map((element) =>
    setAllInElementSubtree(element, fieldName, valueCallback, condition)
  );
}

// setAllInElementSubtree(
//   { key: "hi", paramSender: { path: "root.li_123", uiType: "dropdown" } },
//   "paramSender",
//   createValueCallback(456)
// ); //=
// //@ts-ignore
// clearSchemaKitRunData([{ jsonType: "string", value: "something" }]); //=
// clearSchemaKitRunData([
//   {
//     jsonType: "object",
//     value: null,
//     //@ts-ignore
//     objectPropertySchemas: [{ value: "hi", jsonType: "string" }],
//   },
// ])[0].objectPropertySchemas; //=
// clearSchemaKitRunData([
//   {
//     jsonType: "array",
//     value: null,
//     //@ts-ignore
//     listElements: [{ value: "hi" }, { value: "there" }],
//   },
// ])[0]; //=

// import pe from "./paramExamples";
// clearSchemaKitRunData(pe.failedToClearSimpleSchema as KitRunSchemaElement[]); //=

function _dropFromSchemaElement(
  element: KitRunSchemaElement | InstanceSchemaElement | LegoSchemaElement,
  paths: string[]
): any {
  if (element.jsonType == "object") {
    return oset(
      odrop(element, paths),
      "objectPropertySchemas",
      (element.objectPropertySchemas as KitRunSchemaElement[]).map((e) => {
        return _dropFromSchemaElement(e, paths);
      })
    );
  } else if (element.jsonType == "array") {
    if (paths.includes("listElements")) {
      return odrop(element, paths);
    } else if ("listElements" in element) {
      return oset(
        odrop(element, paths),
        "listElements",
        (element.listElements ?? []).map((e) =>
          _dropFromSchemaElement(e, paths)
        )
      );
    } else {
      return element;
    }
  } else {
    return odrop(element, paths);
  }
}

// _dropFromSchemaElement({} as KitRunSchemaElement); //=
// _dropFromSchemaElement({ value: "derp" } as KitRunSchemaElement); //=
// _dropFromSchemaElement({
//   key: "hi",
//   value: "derp",
//   jsonType: "object",
//   objectPropertySchemas: [
//     {
//       key: "again",
//       value: "derp",
//       jsonType: "object",
//       objectPropertySchemas: [
//         { key: "third", value: "sup", jsonType: "number" },
//       ],
//     },
//     {
//       key: "list",
//       value: null,
//       jsonType: "array",
//       listElements: [
//         { key: "1", value: "2" },
//         { key: "3", value: "4" },
//       ],
//     },
//   ],
// } as KitRunSchemaElement); //=

export function isKitRunSchemaElementField(f: string) {
  return f in kitRunSchemaElementDefaults;
}
